import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_ERROR,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR,
    SET_DECYPTED_AES_KEYS_REQUEST,
    SET_DECYPTED_AES_KEYS_SUCCESS,
    DETERMINE_USER_ROLE_REQUEST,
    DETERMINE_USER_ROLE_SUCCESS,
    ASK_FOR_PASSWORD_SHOW_MODAL,
    ASK_FOR_PASSWORD_REQUEST,
    CANCEL_ASK_PASSWORD,
    ENTER_PASSWORD_ERROR,
    VIRGIL_INIT_ERROR,
    SET_COMPANY_METADATA_SUCCESS,
    SET_DECRYPTING_KEYS
} from '../actions';

const INIT_STATE = {
    user: JSON.parse(localStorage.getItem('userObj')),
    company: JSON.parse(localStorage.getItem('companyObj')),
    decryptingKeys: false,
    forgotUserMail: '',
    resetPasswordCode: '',
    loading: false,
    error: '',
    userRole: '',
    passwordModalVisible: false,
    passwordModalError: "",
    refresh: false, // used to refresh the "Verfiying identity.." text on submit page
    resetPasswordSuccess: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            console.log('in log in reducer')
            return { ...state, loading: true, error: '' };
        case LOGIN_USER_SUCCESS:
            let metadata = action.payload.company['metadata'];
            metadata = metadata.map((field) => {
                field.options.sort((a, b) => {
                    return a.id - b.id
                })
                return field;
            })
            return { ...state, loading: false, user: action.payload.user, 
                company: {
                    ...action.payload.company, 
                    metadata
                },
            error: '' };
        case LOGIN_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };
        case FORGOT_PASSWORD:
            return { ...state, loading: true, error: '' };
        case FORGOT_PASSWORD_SUCCESS:
            return { ...state, loading: false, forgotUserMail: action.payload, error: '' };
        case FORGOT_PASSWORD_ERROR:
            return { ...state, loading: false, forgotUserMail: '', error: action.payload.message };
        case RESET_PASSWORD:
            return { ...state, loading: true, error: '' };
        case RESET_PASSWORD_SUCCESS:
            return { ...state, loading: false, resetPasswordSuccess: true, error: '' };
        case RESET_PASSWORD_ERROR:
            return { ...state, loading: false, error: action.payload.message };
        case REGISTER_USER:
            return { ...state, loading: true, error: '' };
        case REGISTER_USER_SUCCESS:
            let companyObj = null;
            if (action.payload.company) {
                let metadata_ = action.payload.company['metadata'];
                metadata_ = metadata_.map((field) => {
                    field.options.sort((a, b) => {
                        return a.id - b.id
                    })
                    return field;
                })
                companyObj = {
                    ...action.payload.company, 
                    ...metadata_
                }
            }
            
            return { 
                ...state,
                loading: false,
                user: action.payload.user,
                company: companyObj,
                error: '' 
            };
        case REGISTER_USER_ERROR:
            return { ...state, loading: false, user: '', error: action.payload.message };
        case VIRGIL_INIT_ERROR:
            return {...state, user: {
                ...state.user,
                virgilError: true
            },
            refresh: !state.refresh}
        case ENTER_PASSWORD_ERROR:
            return {...state, passwordModalError: action.payload.errorMsg};
        case SET_DECRYPTING_KEYS:
            return {...state, decryptingKeys: action.payload.decryptingKeys}
        case ASK_FOR_PASSWORD_SHOW_MODAL:
            return { ...state, passwordModalVisible: true}
        case CANCEL_ASK_PASSWORD:
            return { ...state, passwordModalVisible: false}
        case SET_DECYPTED_AES_KEYS_REQUEST:
            return {...state};
        case SET_COMPANY_METADATA_SUCCESS:
            action.payload.metadataObj = action.payload.metadataObj.map((field) => {
                field.options.sort((a, b) => {
                    return a.id - b.id
                })
                return field;
            })

            return {...state, company: {
                ...state.company,
                metadata: action.payload.metadataObj
            }}
        case SET_DECYPTED_AES_KEYS_SUCCESS:
            const userObjOld = state.user;
            userObjOld.decryptedAESKeys = action.payload.aesKey;
            return { ...state, user: userObjOld, refresh: !state.refresh };
        case LOGOUT_USER:
            console.log('logout reducer')
            return { ...state, user: null, error: '' };
        case DETERMINE_USER_ROLE_REQUEST: 
            return { ...state, loading: true };
        case DETERMINE_USER_ROLE_SUCCESS:
            return { ...state, userRole: action.payload.role, loading: false };
        default: return { ...state };
    }
}
