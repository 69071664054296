// TODO: Prob a better place to put this

const initial_company_meta_data = [
  {
    "name": "Job Type",
    "type": "select",
    "options": [
      "Lifting box",
      "Welding"
    ]
  },
  {
    "name": "Worker Type",
    "type": "select",
    "options": [
      "General",
      "Welder"
    ]
  },
  {
    "name": "Location",
    "type": "select",
    "options": [
      "Assembly line",
      "Loading bay"
    ]
  }
]

export {
   initial_company_meta_data
};
