import { EThree } from '@virgilsecurity/e3kit-browser';
import { TUMEKE_API } from "../constants/config"
// rm'd auth, function from Database
import * as db from 'tumeke-database';
//import { getUserDataByEmail } from './DatabaseHelpers'
import { auth } from './DatabaseHelpers';

var eThree = null;
var groupChat = null;

const useVirgil = true;

// Assumes that device already authenticated w cognito
export async function initializeVirgil() {
	if (!useVirgil || isVirgilInitialized()) return;
	const getToken = db.virgilJwt;
	const initializeFunction = () => getToken().then(result => result.virgil_token);
	console.log(initializeFunction)
	try {
		eThree = await EThree.initialize(initializeFunction)
		console.log("Successfully initialized virgil")
	} catch (err) {
		console.log("Virgil error: " + err);
	}

}

export function isVirgilInitialized() {
	return eThree !== null && groupChat !== null;
}

export async function logoutVirgil() {
	if (!useVirgil) return;
	try {
		await eThree.cleanup();
	} catch (err) {
		console.log("Error cleaning up " + err)
	}
	eThree = null;
}

export async function createNewUserVirgil(password) {
	if (!useVirgil) return;
	try {
		await eThree.register();
		await eThree.backupPrivateKey(password);
		console.log("Done")
	} catch (err) {
		console.log("Error: " + err);
	}
}

export async function createVirgilGroupWithSelf(uid, groupId) {
	if (!useVirgil) return;
	console.log("Creating group with self: " + uid)
	const participants = await eThree.findUsers([uid]);
	console.log("Got user card")
	groupChat = await eThree.createGroup(groupId, participants);
	console.log("Group done")
	return;
}

export async function encryptMessage(message) {
	if (!useVirgil) return;
	return await groupChat.encrypt(message);
}

export async function decryptMessage(message, messageCreatorUID) {
	if (!useVirgil) return;
	const messageSender = await eThree.findUsers(messageCreatorUID);
	return await groupChat.decrypt(message, messageSender);
}

async function addUserToGroup(uid) {
	console.log('finding users: ' + uid)
	const newParticipant = await eThree.findUsers(uid);
	console.log('adding to groupchat: ' + JSON.stringify(newParticipant))
	try {
		await groupChat.add(newParticipant);
	} catch (e) {
		// this is the error for when user is already in group.
		// if they are not, then throw error
		if (!(String(e).startsWith("KeyknoxClientError"))) {
			throw "Add user to group error"
		}
	}
	
}

export async function acceptUserIntoCompanyVirgilHelper(uid) {
	await addUserToGroup(uid);
}

export async function joinGroup(userObj, companyObj) {
	if (!useVirgil) return;
	if (userObj.role === "requesting") return;
	const { virgil_id, company_admin_virgil_id } = companyObj
	const card = await eThree.findUsers(company_admin_virgil_id);
	groupChat = await eThree.loadGroup(companyObj.virgil_id, card);
}

export async function decryptAESKeys(userObj, companyObj) {
	const { company_admin_virgil_id, aes_key } = companyObj;
	let decryptedObj = {}
	for (var key of Object.keys(aes_key)) {
		decryptedObj[key] = await decryptMessage(aes_key[key], company_admin_virgil_id)
	}
	console.log('decrypted: ' + JSON.stringify(decryptedObj))
	return decryptedObj;
}

export async function changePassword(oldPassword, newPassword) {
	await eThree.changePassword(oldPassword, newPassword);
}

export async function getVirgilPrivateKey(keyPassword, createNewAccount) {
	if (!useVirgil) return;
	const hasLocalKey = await eThree.hasLocalPrivateKey()
	console.log("key password: " + keyPassword)
	if (!hasLocalKey) {
		try {
			await eThree.restorePrivateKey(keyPassword);
		} catch (err) {
			console.log(err);
			if (createNewAccount) {
				createNewUserVirgil(keyPassword)
			} else {
				console.log("key password: " + keyPassword);
				throw new Error("No local key exists")
			}

		}
	}
}

export {
	eThree
};
