import { Colors } from './constants'

const commonConfigs = {
	pointBorderWidth: 5,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 1,
    pointRadius: 0,
    fill: false,
    borderWidth: 2,
}

export const ChartConfig = {
	"RedSolid" : {
		label: 'RedSolid',
		borderColor: "red",
		pointBorderColor: "red",
		pointBackgroundColor: "red",
		pointHoverBackgroundColor: "red",
		pointHoverBorderColor: "red",
		belongs: (value, jointMetadata, chunk, index, jointType) => {
			return getColor(value, jointType) === Colors["Red"] && !isDashedHere(jointMetadata, chunk, index, jointType)
		},
		...commonConfigs
    },
    "RedDashed" : {
		label: 'RedDashed',
		borderColor: "red",
		pointBorderColor: "red",
		pointBackgroundColor: "red",
		pointHoverBackgroundColor: "red",
		pointHoverBorderColor: "red",
		borderDash: [15, 5],
		belongs: (value, jointMetadata, chunk, index, jointType) => {
			return getColor(value, jointType) === Colors["Red"] && isDashedHere(jointMetadata, chunk, index, jointType)
		},
		...commonConfigs
    },
    "YellowSolid" : {
        label: 'YellowSolid',
        borderColor: "#e6e340",
        pointBorderColor: "#e6e340",
        pointBackgroundColor: "#e6e340",
        pointHoverBackgroundColor: "#e6e340",
        pointHoverBorderColor: "#e6e340",
        belongs: (value, jointMetadata, chunk, index, jointType) => {
        	return getColor(value, jointType) === Colors["Yellow"] && !isDashedHere(jointMetadata, chunk, index, jointType)
        },
        ...commonConfigs
    },
    "YellowDashed" : {
        label: 'YellowDashed',
        borderColor: "#e6e340",
        pointBorderColor: "#e6e340",
        pointBackgroundColor: "#e6e340",
        pointHoverBackgroundColor: "#e6e340",
        pointHoverBorderColor: "#e6e340",
        borderDash: [15, 5],
        belongs: (value, jointMetadata, chunk, index, jointType) => {
        	return getColor(value, jointType) === Colors["Yellow"] && isDashedHere(jointMetadata, chunk, index, jointType)
        },
        ...commonConfigs
    },
    "GreenSolid" : {
        label: 'GreenSolid',
        borderColor: "rgb(109, 208, 163)",
        pointBorderColor: "rgb(109, 208, 163)",
        pointBackgroundColor: "rgb(109, 208, 163)",
        pointHoverBackgroundColor: "rgb(109, 208, 163)",
        pointHoverBorderColor: "rgb(109, 208, 163)",
        belongs: (value, jointMetadata, chunk, index, jointType) => {
        	return getColor(value, jointType) === Colors["Green"] && !isDashedHere(jointMetadata, chunk, index, jointType)
        },
        ...commonConfigs
    },
    "GreenDashed" : {
        label: 'GreenDashed',
        borderColor: "rgb(109, 208, 163)",
        pointBorderColor: "rgb(109, 208, 163)",
        pointBackgroundColor: "rgb(109, 208, 163)",
        pointHoverBackgroundColor: "rgb(109, 208, 163)",
        pointHoverBorderColor: "rgb(109, 208, 163)",
        borderDash: [15, 5],
        belongs: (value, jointMetadata, chunk, index, jointType) => {
        	return getColor(value, jointType) === Colors["Green"] && isDashedHere(jointMetadata, chunk, index, jointType)
        },
        ...commonConfigs
    },
    "BlackSolid" : {
        label: 'BlackSolid',
        borderColor: "#645aff",
        pointBorderColor: "#645aff",
        pointBackgroundColor: "#645aff",
        pointHoverBackgroundColor: "#645aff",
        pointHoverBorderColor: "#645aff",
        belongs: (value, jointMetadata, chunk, index, jointType) => {
        	return getColor(value, jointType) === Colors["Black"] && !isDashedHere(jointMetadata, chunk, index, jointType)
        },
        ...commonConfigs
    },
    "BlackDashed" : {
        label: 'BlackDashed',
        borderColor: "#645aff",
        pointBorderColor: "#645aff",
        pointBackgroundColor: "#645aff",
        pointHoverBackgroundColor: "#645aff",
        pointHoverBorderColor: "#645aff",
        borderDash: [15, 5],
        belongs: (value, jointMetadata, chunk, index, jointType) => {
        	return getColor(value, jointType) === Colors["Black"] && isDashedHere(jointMetadata, chunk, index, jointType)
        },
        ...commonConfigs
    }
}

function isDashedHere(jointMetadata, chunk, index, jointType) {
	if (!jointMetadata) return false;
	if (!jointMetadata.angleTypeUsed) return false;
	const ranges = jointMetadata.angleTypeUsed;
	const trueIndex = chunk * 600 + index;
	
	for (const rangeId in ranges[jointType]) {
		const range = ranges[jointType][rangeId];
		if (trueIndex >= range[0] && trueIndex <= range[1]) {
			return true;
		}
	}
	return false;
}

function getColor(value, jointType) {
  switch(jointType) {
    case 'Left elbow':
      if (value > 60 && value < 100) {
        return Colors["Green"]
      }
      break;
    case 'Right elbow':
      if (value > 60 && value < 100) {
        return Colors["Green"]
      }
      break;
    case 'Hip':
      if (value < 20) {
        return Colors["Green"]
      } else if (value < 60) {
        return Colors["Yellow"]
      }
      break;
    case 'Left shoulder':
      if (value < 45) {
        return Colors["Green"]
      } else if (value < 90) {
        return Colors["Yellow"]
      }
      break;
    case 'Right shoulder':
      if (value < 45) {
        return Colors["Green"]
      } else if (value < 90) {
        return Colors["Yellow"]
      }
      break;
    case 'Neck':
      return Colors["Black"]
      break;
    case 'Left wrist':
      return Colors["Black"]
      break;
    case 'Right wrist':
      return Colors["Black"]
      break;
  }
  return Colors["Red"]
}