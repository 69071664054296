import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  SET_DECYPTED_AES_KEYS_REQUEST,
  SET_DECYPTED_AES_KEYS_SUCCESS,
  DETERMINE_USER_ROLE_REQUEST,
  DETERMINE_USER_ROLE_SUCCESS,
  ASK_FOR_PASSWORD_SHOW_MODAL,
  ASK_FOR_PASSWORD_REQUEST,
  CANCEL_ASK_PASSWORD,
  ENTERED_PASSWORD_REQUEST,
  ENTER_PASSWORD_ERROR,
  CHECK_PASSWORD,
  VIRGIL_INIT_ERROR,
  SET_COMPANY_METADATA_SUCCESS,
  SET_DECRYPTING_KEYS
} from '../actions';

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history }
});

export const cancelAskPassword = () => ({
  type: CANCEL_ASK_PASSWORD,
  payload: { }
});

export const checkPassword = (history) => ({
  type: CHECK_PASSWORD,
  payload: { history }
})

export const enteredPasswordRequest = (password) => ({
  type: ENTERED_PASSWORD_REQUEST,
  payload: { password }
});

export const virgilInitError = () => ({
  type: VIRGIL_INIT_ERROR,
  payload: {}
})

export const enterPasswordError = (errorMsg) => ({
  type: ENTER_PASSWORD_ERROR,
  payload: { errorMsg }
})

export const askForPasswordShowModal = () => ({
  type: ASK_FOR_PASSWORD_SHOW_MODAL,
  payload: {  }
});

export const askForPasswordRequest = () => ({
  type: ASK_FOR_PASSWORD_REQUEST,
  payload: {  }
});

export const loginUserSuccess = (user, company) => ({
  type: LOGIN_USER_SUCCESS,
  payload: { user, company }
});

export const setDecyptingKeys = (decryptingKeys) => ({
  type: SET_DECRYPTING_KEYS,
  payload: { decryptingKeys }
})

export const setDecryptedAESKeysRequest = (callback, password) => ({
  type: SET_DECYPTED_AES_KEYS_REQUEST,
  payload: { callback, password }
});

export const setDecryptedAESKeysSuccess = (aesKey, callback) => ({
  type: SET_DECYPTED_AES_KEYS_SUCCESS,
  payload: { aesKey, callback }
});

export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message }
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history }
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message }
});

export const resetPassword = (oldPassword, newPassword_1, newPassword_2) => ({
  type: RESET_PASSWORD,
  payload: { oldPassword, newPassword_1, newPassword_2 }
});
export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message }
});

export const setCompanyMetadataSuccess = (metadataObj) => ({
  type: SET_COMPANY_METADATA_SUCCESS,
  payload: { metadataObj }
})

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history }
})
export const registerUserSuccess = (user, company) => ({
  type: REGISTER_USER_SUCCESS,
  payload: { user, company }
})
export const registerUserError = (message) => ({
  type: REGISTER_USER_ERROR,
  payload: { message }
})

export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history }
});

export const determineUserRoleRequest = (uid) => ({
  type: DETERMINE_USER_ROLE_REQUEST,
  payload: { uid }
})

export const determineUserRoleSuccess = (role) => ({
  type: DETERMINE_USER_ROLE_SUCCESS,
  payload: { role }
})
