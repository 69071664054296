
import axios from 'axios';
import { all, call, fork, put, takeEvery, select, take } from 'redux-saga/effects';
import { eventChannel } from "redux-saga"
// rm'd auth, database
import { getVideosForUser, getGroup } from '../../helpers/DatabaseHelpers';
import { ProcessingStatus } from '../../constants/maps';
import { TUMEKE_API } from '../../constants/config';
import { NotificationManager } from "../../components/common/react-notifications";

import {
  updateAdditionalInfoFirebaseHelper,
  updateRiskComponentsFirebaseHelper,
  getVideoJsons,
  deleteVideosFirebaseHelper,
  addMetadataOptionFirebaseHelper,
  deleteCompanyMetadataOptionFirebaseHelper,
  editMetadataOptionFirebaseHelper,
  setVideoMetadataFirebaseHelper,
  getAllMetadataFieldsFirebaseHelper,
  submitVideoFeedbackFirebaseHelper,
  getThumbnail,
  generateReport,
  getAllUserVideos
} from '../../helpers/DatabaseHelpers';

import {
  GET_VIDEO_LIST_REQUEST,
  GET_VIDEO_SUCCESS,
  UPLOAD_RISK_COMPONENTS,
  UPLOAD_ADDITIONAL_INFO,
  GET_JOINT_DATA,
  DELETE_VIDEOS_REQUEST,
  ADD_METADATA_OPTION,
  DELETE_METADATA_OPTION,
  EDIT_METADATA_OPTION,
  SET_VIDEO_METADATA,
  GET_ALL_METADATA_FIELDS,
  ADD_SINGLE_VIDEO_LISTENER,
  REMOVE_VIDEO_LISTENERS,
  GENERATE_REPORT_REQUEST,
  GENERATE_REPORT_ERROR,
  GET_THUMBNAIL_REQUEST,
  SUBMIT_VIDEO_FEEDBACK
} from '../actions'

import {
  getVideoListRequest,
  addNewVideo,
  allVideosLoaded,
  loadingVideos,
  modifyVideoDoc,
  getJointDataSuccess,
  deleteVideos,
  addMetadataOption,
  deleteMetadataOption,
  editMetadataOption,
  setVideoMetadata,
  getAllMetadataFields,
  getAllMetadataFieldsSuccess,
  addSingleVideoListener,
  deleteVideosSuccess,
  generateReportError,
  generateReportSuccess,
  getThumbnailRequest,
  getThumbnailSuccess,
  setVideoMetadataSuccess
} from './actions'

import {
  logoutUser,
  setCompanyMetadataSuccess
} from '../auth/actions'

import {
  TUMEKE_WEBSOCKET_API
} from '../../constants/config'


import {Mixpanel} from "../../helpers/Mixpanel";

export function* watchGetVideoListRequest() {
    yield takeEvery(GET_VIDEO_LIST_REQUEST, getVideoListSaga);
}

export function* watchGetJointDataRequest() {
    yield takeEvery(GET_JOINT_DATA, getJointDataSaga);
}

export function* watchUploadInputsRequest() {
    yield takeEvery(UPLOAD_ADDITIONAL_INFO, updateAdditionalInputsSaga);
}

export function* watchUploadRiskComponentsRequest() {
    yield takeEvery(UPLOAD_RISK_COMPONENTS, updateRiskComponentsSaga);

}

export function* watchGenerateReportError() {
  yield takeEvery(GENERATE_REPORT_ERROR, generateReportErrorSaga)
}


export function* watchGenerateReportRequest() {
  yield takeEvery(GENERATE_REPORT_REQUEST, generateReportSaga)
}

export function* watchDeleteVideoRequest () {
  yield takeEvery(DELETE_VIDEOS_REQUEST, deleteVideoSaga);
}

export function* watchAddMetadataOptionRequest() {
  yield takeEvery(ADD_METADATA_OPTION, addMetadataOptionSaga)
}

export function* watchDeleteMetadataOptionRequest() {
  yield takeEvery(DELETE_METADATA_OPTION, deleteMetadataOptionSaga)
}

export function* watcheditMetadataOptionRequest() {
  yield takeEvery(EDIT_METADATA_OPTION, editMetadataOptionSaga)
}

export function* watchGetThumbnailRequest() {
  yield takeEvery(GET_THUMBNAIL_REQUEST, getThumbnailRequestSaga)
}

export function* watchsetVideoMetadataRequest() {
  yield takeEvery(SET_VIDEO_METADATA, setVideoMetadataSaga)
}

export function* watchAddSingleVideoListener() {
  yield takeEvery(ADD_SINGLE_VIDEO_LISTENER, singleVideoListenerSaga)
}

export function* watchgetAllMetadataFieldsRequest() {
  yield takeEvery(GET_ALL_METADATA_FIELDS, getAllMetadataFieldsSaga)
}

export function* watchSubmitVideoFeedbackRequest() {
  yield takeEvery(SUBMIT_VIDEO_FEEDBACK, getSubmitVideoFeedbackRequestSaga)
}


const getUserListAsync = async (companyId, group_id) => {
  // todo company id not needed
  const id = undefined;
  const group = await getGroup(id)
  return group.users;
}

const registerNewVideoListener = (videoId) => {
  return eventChannel(emit => {
    const handleNewVideo = async (snapshotDoc) => {
      const videoData = {
        ...snapshotDoc.data,
        metadata: snapshotDoc.metadata,
        user_id: snapshotDoc.user_id,
        key: snapshotDoc.id,
        id: snapshotDoc.id,
        visible: true
      }
      if (videoData === undefined) {
        return;
      }
      let thumbnailRealLoc = "/assets/img/placeholder.jpg";
      if (videoData.hasOwnProperty("thumbnailLoc")) {
        console.log("HAS THUMBNAIL LOC: " + videoData.thumbnailLoc)
        thumbnailRealLoc = await getThumbnailName(videoData.id);
      }
      emit({action: "ADD", data: {
        ...videoData, thumbnailLoc: thumbnailRealLoc, key: snapshotDoc.id, visible: true}
      })
    }

    const channel = new WebSocket(TUMEKE_WEBSOCKET_API + '/registerVideoListener/'+videoId);
    channel.onmessage = (event) => {
      const resp = JSON.parse(event.data);
      console.log("RESP: " + event.data)
      if ("message" in resp) {
        if (resp["message"] === "Bad auth") {
          channel.close();
          return;
        }

      }
      if ("data" in resp) {
        handleNewVideo(resp["data"])
      }
    };
  
    channel.onopen = () => {
      const id_token = localStorage.getItem("id_token")
      channel.send(id_token);
    }

    // todo listener rework
    // const channel = database.collection("users").doc(uid).
    //                 collection("videos").doc(videoId).onSnapshot(handleNewVideo);
    //
    const unsubscribe = () => {
      channel.close();
    }
    //
    return unsubscribe;
  })
}


const getThumbnailName = async (videoId) => {
  // todo get thumbnail from server
  const realLoc = await getThumbnail(videoId);
  return realLoc.url;
}

function* getJointDataSaga({ payload }) {
  const { videoId, chunk, personId } = payload;
  const state = yield select();
  console.log("Video id: " + videoId);
  const uid = state.videos.videoList[videoId].uid;
  const data = yield call(getVideoJsons, uid, videoId, personId,
    state.videos.videoList[videoId].jointMetadata, chunk);
  yield put(getJointDataSuccess(videoId, data, chunk));
}

function* deleteVideoSaga({ payload }) {
  const { videoIds, userId } = payload;
  const state = yield select();
  const currentUid = state.authUser.user.id;
  const userIds = [];
  for (const videoId of videoIds) {
    const video = state.videos.videoList[videoId];

    if (video.processingStatus !== ProcessingStatus["COMPLETED"] &&
        video.processingStatus !== ProcessingStatus["ERROR"]) {
      NotificationManager.warning(
        "Cannot delete a video that is processing",
        "Delete error",
        5000,
        null,
        null,
        ''
      )
      return;

    }
    if (video.uid !== userId) {
      console.log("ID: " + video.user_id + " user: " + userId)
       NotificationManager.warning(
        "You've selected videos that belong to a different user. \
        You can only delete your own videos",
        "Delete error",
        5000,
        null,
        null,
        ''
      )
      return;

    }
    userIds.push(video.uid);
  }
  yield put(deleteVideosSuccess(videoIds));
  yield call(deleteVideosFirebaseHelper, userIds, videoIds);
};

function* addMetadataOptionSaga({ payload }) {
  const { fieldId, optionName } = payload;
  const state = yield select();
  const ret = yield call(addMetadataOptionFirebaseHelper, fieldId, optionName);
  console.log("new company metadata: " + JSON.stringify(ret))
  yield put(setCompanyMetadataSuccess(ret['metadata']))
};

function* deleteMetadataOptionSaga({ payload }) {
  const { companyId, fieldId, optionId } = payload;
  yield call(deleteCompanyMetadataOptionFirebaseHelper, companyId, fieldId, optionId);
};

function* editMetadataOptionSaga({ payload }) {
  const { optionId, optionName } = payload;
  const ret = yield call(editMetadataOptionFirebaseHelper, optionId, optionName);
  console.log("new company metadata: " + JSON.stringify(ret))
  yield put(setCompanyMetadataSuccess(ret['metadata']))
};

function* setVideoMetadataSaga({ payload }) {
  const { videoId, fieldId, optionId } = payload;
  const state = yield select();
  const uid = state.authUser.user.uid;
  const ret = yield call(setVideoMetadataFirebaseHelper, videoId, fieldId, optionId);
  yield put(setVideoMetadataSuccess(videoId, ret['metadata']))
};

function* getAllMetadataFieldsSaga({ payload }) {
  const { videoId } = payload;
  let data = {};
  try {
    const state = yield select();
    const companyObj = state.authUser.company;
    console.log("metadata: " + JSON.stringify(state.videos.videoList[videoId]['metadata']))
    // data = yield call(getAllMetadataFieldsFirebaseHelper, companyObj['meta_data'], videoId);
  } catch (err) {
    console.log("Metadata Error: " + err);
  }
  yield put(getAllMetadataFieldsSuccess(data));
};

function* getSubmitVideoFeedbackRequestSaga({ payload }) {
  const { rating, feedbackText } = payload;
  //yield call(submitVideoFeedbackFirebaseHelper, rating, feedbackText)
}

function* updateRiskComponentsSaga({ payload }) {
  const { videoId, personId } = payload;
  const state = yield select();
  yield call(
    updateRiskComponentsFirebaseHelper,
    videoId,
    personId,
    state.videos.videoList[videoId].riskAssessment[personId].riskComponents
  );
  yield call(mixpanelTrackEvent, 'RS - Event - Update Model Detected Inputs');
  // const recomputeRisk = functions.httpsCallable('recomputeRisk');
  // TODO: Get the cloud functions to work

  // recomputeRisk({videoId: videoId}).then(function(result) {
  // });
  // const computeAggregateRisk = functions.httpsCallable('computeAggregateRisk');
  // const { groupId, companyId } = JSON.parse(localStorage.getItem("userObj"))
  // computeAggregateRisk({groupId: groupId, companyId: companyId}).then(function(result) {
  //   console.log(result)
  // });

}

export async function mixpanelTrackEvent(event) {
  await Mixpanel.track(event);
}

function* closeChannelIfNecessary(listener) {
  while (true) {
    yield take (REMOVE_VIDEO_LISTENERS);
    listener.close();
  }
}

function* updateAdditionalInputsSaga({ payload }) {

  const { videoId, personId } = payload;
  const state = yield select();
  console.log("IN SAGA FOR UPDATE ADDITIONAL INPUTS");
  yield call(
    updateAdditionalInfoFirebaseHelper, 
    videoId,
    personId,
    state.videos.videoList[videoId].riskAssessment[personId].additionalInputs
  );
  yield call(mixpanelTrackEvent, 'RS - Event - Update Manual Inputs');
  // const recomputeRisk = functions.httpsCallable('recomputeRisk');
  // TODO: Get the cloud functions to work

  // recomputeRisk({videoId: videoId}).then(function(result) {
  // });
  // const computeAggregateRisk = functions.httpsCallable('computeAggregateRisk');
  // const { groupId, companyId } = JSON.parse(localStorage.getItem("userObj"))
  // computeAggregateRisk({groupId: groupId, companyId: companyId}).then(function(result) {
  //   console.log(result)
  // });

}

function* singleVideoListenerSaga({ payload }) {
  // // This is the redux-saga way of listening to updates from Firebase
  const { videoId } = payload;
  const listener = registerNewVideoListener(videoId);
  yield fork(closeChannelIfNecessary, listener);
  while (true) {
    const state = yield select();
    try {
      const payload = yield take(listener);
      if (payload.action === "ADD") {
        yield put(addNewVideo({
          ...payload.data,
        }));
      }
    } catch (err) {
      console.log("Error: " + err);
    }
  }
}


function* generateReportErrorSaga({ payload }) {
  NotificationManager.warning(
    "Reports only available for new videos",
    "Report generation error",
    5000,
    null,
    null,
    ''
  )
}

async function parseBlobHelper(blob) {
  return await blob.text()
}
function* generateReportSaga({ payload }) {
  const { videoId, subjectId } = payload;
  let response = null;
  try {
    response = yield call(generateReport, videoId, subjectId);
  } catch (e) {
    yield put(generateReportError());
    return;
  }
  console.log("response: " + JSON.stringify(response))
  const link = document.createElement('a');
  link.href = response.url;
  link.setAttribute('download', 'report.pdf');
  document.body.appendChild(link);
  link.click();
  yield put(generateReportSuccess());
}

function* getThumbnailRequestSaga({ payload }) {
  const { videoId, remoteThumbnailLoc } = payload;
  if (remoteThumbnailLoc === undefined) {
    return;
  }
  let thumbnailRealLoc = yield call(getThumbnailName, videoId);
  yield put(getThumbnailSuccess(videoId, thumbnailRealLoc));
}

const waitTime = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

function* getVideoListSaga({ payload }) {
  const { userId, companyId, groupId } = payload;
  const { history } = payload;
  const state = yield select();
  const { videosLoaded, videosLoading } = state.videos;
  console.log("Videos loaded: " + videosLoaded)
  console.log("Videos loading: " + videosLoading)

  if (videosLoaded || videosLoading) {
    return;
  }
  yield put(loadingVideos());
  let userList = null;
  
  let videoList = ((yield call(getAllUserVideos)))["videos"];
  for (let i = 0; i < videoList.length; i++) {
    const videoData = videoList[i].data;
    videoList[i].metadata.sort((a, b) => {
      return a.option_id < b.option_id
    })
    if (videoData.processingStatus !== ProcessingStatus["COMPLETED"] &&
        videoData.processingStatus !== ProcessingStatus["ERROR"]) {
      yield put(addSingleVideoListener(videoList[i].id))
      continue;
    }
    yield put(addNewVideo({
      ...videoData,
      metadata: videoList[i].metadata,
      user_id: videoList[i].user_id,
      thumbnailLoc: "/assets/img/placeholder.jpg",
      key: videoList[i].id,
      visible: true
    }))
    yield call(waitTime, 1);
    if (videoData.hasOwnProperty("thumbnailLoc")) {
      yield put(getThumbnailRequest(videoList[i].id, videoData.thumbnailLoc))
    }
  }

  console.log("All videos loaded")
  yield put(allVideosLoaded())

}

export default function* rootSaga() {
    yield all([
        fork(watchGetVideoListRequest),
        fork(watchUploadInputsRequest),
        fork(watchGetJointDataRequest),
        fork(watchUploadRiskComponentsRequest),
        fork(watchDeleteVideoRequest),
        fork(watchAddMetadataOptionRequest),
        fork(watchDeleteMetadataOptionRequest),
        fork(watcheditMetadataOptionRequest),
        fork(watchsetVideoMetadataRequest),
        fork(watchgetAllMetadataFieldsRequest),
        fork(watchAddSingleVideoListener),
        fork(watchGenerateReportRequest),
        fork(watchGenerateReportError),
        fork(watchGetThumbnailRequest),
        fork(watchSubmitVideoFeedbackRequest),
    ]);
}
