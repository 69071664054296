import { ChartConfig } from "./ChartConfig";
import { Colors } from './constants'

// TODO(znoland): move server side and do in Python?
// TODO(znoland): make rolling window a variable that can be changed by the user.
function cleanJointData(angleArray) {
    const rollingWindow = 3; // 20
    const rollingWindowDenominator = rollingWindow * 2 + 1;
    /*
     * Fill gaps in data and set -1 to 0
     *
     * Note:
     *   - if degree is >-1, then get a previous value in the last 10 frames. If no previous values in last 10 frames
     *     are >-1, set as 0
     */
    // const cleanedData =  angleArray.map(
    //    (degree, index) =>
    //      (degree >= 0) ?
    //        degree :
    //        angleArray.slice(0, index).reverse().find((value) => value >= 0)

    /*
     * Get rolling 10 frame window, offset by 1, reverse order so findIndex gets the latest degree >0.
     * (findIndex always gets first match in array)
     * Then offset index by the number of frames/indexes away the new value is
     * (+2 since zero indexed and the slice is already 1 frame behind)
     */

    // If -1 set to 0
    //  )
    // let lastNonnegative = 0;
    // for (let i = 0; i < angleArray.length; i++) {
    //   if (angleArray[i] == -1) {
    //     angleArray[i] = null;
    //   }
    //   if (angleArray[i] == undefined) {
    //     angleArray[i] = null;
    //   }
    // }
   // console.log (angleArray)
   // Get rolling average across X prior and X leading frames
    const averagedData = angleArray;
    for (let i = 0; i < averagedData.length; i++) {
      if (averagedData[i] < 0) {
        averagedData[i] = null;
      }
    }

    /*
    const averagedData = angleArray.map((degree, index) =>
      index >= rollingWindow
        ? angleArray
            .slice(index - rollingWindow, index + rollingWindow)
            .reduce((a, b) => a + b) / rollingWindowDenominator
        : degree,
    );
    */
    return averagedData;
};


export function formatData(allData, chunk, jointMetadata, activeTab)  {
  const frames = allData.length
  var length = frames / 30;

  allData = cleanJointData(allData);
  let start = 0;
  let end = length;
  let label = [0];
  while(start < end) {
    start += length / frames;
    label.push(start)
  }

  let arrays = []
  let i = 0
  for(const key of Object.keys(ChartConfig)) {
    const data = allData.map((degree, index) => ChartConfig[key].belongs(degree, jointMetadata, chunk, index, activeTab) ? degree : null)
    arrays.push({...JSON.parse(JSON.stringify(ChartConfig[key])), data: data})
  }

  return { 
    labels: label,
    datasets: arrays
  }
}
