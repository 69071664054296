// This is meant to be just an enum-type, add any extra metadata to other map
export const Assessments = {
    1 : 'RULA',
    2: 'REBA',
    3: 'NIOSH',
    RULA: 1,
    REBA: 2,
    NIOSH: 3,
};

export const AssessmentsColor = {
    1 : {
        name: 'RULA',
        color: '#34495e'
    },
    2 : {
        name: 'REBA',
        color: '#2980b9'
    },
    3 : {
        name: 'NIOSH',
        color: '#7f8c8d'
    },
}

export const ProcessingStatus = {
    1 : "PROCESSING",
    2 : "MUXING",
    3 : "COMPLETED",
    4 : "ERROR",
    "PROCESSING" : 1,
    "MUXING" : 2,
    "COMPLETED" : 3,
    "ERROR" : 4
}

export const PageErrors = {
    ROLE_ERROR: 1,
    VIRGIL_ERROR: 2
}