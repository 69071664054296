import {
	ACCEPT_USER_INTO_COMPANY_REQUEST,
	ACCEPT_USER_INTO_COMPANY_SUCCESS,
	INITIALIZE_REQUEST_LIST_REQUEST,
    INITIALIZE_REQUEST_LIST_SUCCESS,
    INITIALIZE_GROUPS_LIST_REQUEST,
    INITIALIZE_GROUPS_LIST_SUCCESS,
    CREATE_NEW_GROUP_REQUEST,
    CREATE_NEW_GROUP_SUCCESS,
    DELETE_GROUP_REQUEST,
    DELETE_GROUP_SUCCESS,
    MOVE_USER_TO_GROUP_REQUEST,
    MOVE_USER_TO_GROUP_SUCCESS,
    ADD_USER_TO_GROUP_REQUEST,
    ADD_USER_TO_GROUP_SUCCESS,
    DENY_USER_REQUEST,
    DENY_USER_SUCCESS,
} from '../actions'

const INIT_STATE = {
    usersToAccept: [],
    loading: false,
    userGroups: [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ACCEPT_USER_INTO_COMPANY_REQUEST:
            return { ...state, loading: true };
        case INITIALIZE_REQUEST_LIST_REQUEST:
        	return { ...state };
       	case INITIALIZE_REQUEST_LIST_SUCCESS:
       		return { ...state, usersToAccept: action.payload.requestList };
        case ACCEPT_USER_INTO_COMPANY_SUCCESS:
            return { ...state, usersToAccept: state.usersToAccept.filter((user) => user.id !== action.payload.userId) };
        case INITIALIZE_GROUPS_LIST_REQUEST:
            return { ...state };
        case INITIALIZE_GROUPS_LIST_SUCCESS:
            return { ...state, userGroups: action.payload.userGroups }
        case CREATE_NEW_GROUP_REQUEST:
            return { ...state, loading: true };
        case CREATE_NEW_GROUP_SUCCESS:
            return { ...state, userGroups: action.payload.userGroups, loading: false }
        case DELETE_GROUP_REQUEST:
            return { ...state, loading: true };
        case DELETE_GROUP_SUCCESS:
            return Object.assign({}, state, {
                userGroups: state.userGroups.filter(function(group) {
                    return group.key !== action.payload.groupId;
                })
            });
        case MOVE_USER_TO_GROUP_REQUEST:
            return state;

        case MOVE_USER_TO_GROUP_SUCCESS:
            let userDoc = undefined;
            let breakFlag = false;

            let userGroupsNew = state.userGroups.filter(function(groupObj) {
                if (groupObj.key === action.payload.fromGroup) {
                    groupObj.userData = groupObj.userData.filter(function(user) {
                        if (user.id === action.payload.id) {
                            userDoc = user;
                        }
                        return user.id !== action.payload.id
                    })
                }
                return true;
            });

            // Loop through; find the group you're moving to & insert the obj
            userGroupsNew = userGroupsNew.filter(function(groupObj) {
                if (groupObj.key === action.payload.toGroup) {
                    groupObj.userData.push(userDoc);
                }
                return true;
            });
            return Object.assign({}, state, {
                userGroups: userGroupsNew,
                loading:false
            });

        case ADD_USER_TO_GROUP_REQUEST:
            return { ...state, loading: true }
        case ADD_USER_TO_GROUP_SUCCESS:
            return {...state, userGroups: action.payload.userGroups, loading: false }
        case DENY_USER_REQUEST:
            return { ...state, loading: true }
        case DENY_USER_SUCCESS:
            return { ...state, usersToAccept: state.usersToAccept.filter((user) => user.id !== action.payload.userId) };
           
        default: return { ...state };
    }
}
