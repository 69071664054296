import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import authUser from './auth/reducer';
import adminProfile from './adminProfile/reducer';
import videos from './videos/reducer';
import notifications from './notifications/reducer';
import { LOGOUT_USER } from "./actions"
const reducers = combineReducers({
  menu,
  settings,
  authUser,
  adminProfile,
  notifications,
  videos
});

const rootReducer = (state, action) => {   
   // Clear all data in redux store to initial.
   if(action.type === LOGOUT_USER)
      state = undefined;
   
   return reducers(state, action);
};



export default rootReducer;
