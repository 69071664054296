import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

import { FIREBASE_CONFIG } from '../constants/config'

firebase.initializeApp(FIREBASE_CONFIG);

const auth = firebase.auth();
const database = firebase.firestore();
const functions = firebase.functions();
const storage = firebase.storage();

export {
   auth,
   database,
   functions,
   storage
};
