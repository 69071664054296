import {
    ADD_NOTIFICATION_RECORD,
    ADD_NOTIFICATION_RECORD_SUCCESS,
    DELETE_NOTIFICATION_RECORD,
    START_NOTIFICATION_LISTENER,
    SET_LISTENER_ACTIVE,
    REMOVE_NOTIFICATION_LISTENER
} from '../actions'

export const addNotificationRecord = (notification, key) => ({
    type: ADD_NOTIFICATION_RECORD,
    payload: { notification, key }
});

export const setListenerActive = () => ({
    type: SET_LISTENER_ACTIVE,
    payload: {}
})

export const addNotificationRecordSuccess = (userId) => ({
    type: ADD_NOTIFICATION_RECORD_SUCCESS,
    payload: { userId }
});

export const deleteNotificationRecord = (notificationId) => ({
    type: DELETE_NOTIFICATION_RECORD,
    payload: { notificationId }
});

export const startNotificationListener = () => ({
	type: START_NOTIFICATION_LISTENER,
    payload: { }
})

export const removeNotificationListener = () => ({
    type: REMOVE_NOTIFICATION_LISTENER,
    payload: { }
})