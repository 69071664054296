import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import adminProfileSagas from './adminProfile/saga';
import videosSagas from './videos/saga';
import notificationsSagas from './notifications/saga';

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    adminProfileSagas(),
    videosSagas(),
    notificationsSagas()
  ]);
}
