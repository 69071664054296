import * as db from 'tumeke-database';
import { eThree, createGroupWithSelf,
         encryptMessage, decryptMessage,
         acceptUserIntoCompanyVirgilHelper,
          createVirgilGroupWithSelf} from './VirgilHelpers';
import { TUMEKE_API } from "../constants/config"
import { initial_company_meta_data } from './Constants.js'
import { formatData } from './charts/ProcessJoints.js';
import AsyncStorage from '@react-native-community/async-storage';
import axios from 'axios';
/*

Sagas deal with all errors

*/

// Keeping in DRY
async function getCurrentUser() {
  return JSON.parse(localStorage.getItem("userObj"))
}

export async function checkLoginStatus() {
  return await db.checkLoginStatus();
}

// Used to generate arbitrary document IDs
const generatePushID = (function() {
  // Modeled after base64 web-safe chars, but ordered by ASCII.
  var PUSH_CHARS = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

  // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
  var lastPushTime = 0;

  // We generate 72-bits of randomness which get turned into 12 characters and appended to the
  // timestamp to prevent collisions with other clients.  We store the last characters we
  // generated because in the event of a collision, we'll use those same characters except
  // "incremented" by one.
  var lastRandChars = [];

  return function() {
    var id = "";
    for (let i = 0; i < 20; i++) {
    	id += PUSH_CHARS[Math.floor(Math.random() * PUSH_CHARS.length)]
    }
    return id;
  };
})();


export async function loginUser() {
  return await db.loginUser()
}

export async function addMetadataOptionFirebaseHelper(field_id, option_name) {
    return await db.addCompanyMetadataOption(field_id, option_name)
};

export async function getCompanyById(id) {
  return await db.getCompanyById(id);
}

export async function deleteCompanyMetadataOptionFirebaseHelper(field_id, option_index) {
  await db.deleteCompanyMetadataOption({field_id, option_index})
};

export async function generateReport(videoId, subjectId) {
  return await db.generateReport(videoId, subjectId);
}

export async function migrateFirebaseIdtoCognito(email) {
  await db.migrateFirebaseIdtoCognito(email)
}

export async function editMetadataOptionFirebaseHelper(option_id, option_name) {
  return await db.editCompanyMetadataOption(option_id, option_name)
};

export async function setVideoMetadataFirebaseHelper(video_id, field_id, option_id) {
  const ret = await db.setVideoMetadata(video_id, field_id, option_id)
  return ret
};

// export async function  getAllMetadataFieldsFirebaseHelper(company_metadata, video_id) {
//   const current_user = getCurrentUser()
//   /* Get fields and options */

//   Object.keys(company_metadata).forEach((key) => {
//     console.log('metadata company 2: ' + key)
//     optionsMetadataArray.push({...metadataFieldData[key], key});
//   });

//   console.log("options array: " + JSON.stringify(optionsMetadataArray))
//   /* Get currently selected values */
//   const video = await db.getVideoDoc(video_id);
//   const videoMetadataData = video['meta_data'];
//   let selectedMetadataObj = {};
//   if (videoMetadataData !== undefined) {
//     videoMetadataData.forEach((metadataField) => {
//       const data = metadataField.data();
//       const id = metadataField.id;
//       selectedMetadataObj[id] = data;
//       // selectedMetadataObj.push({...data, "key" : id});
//     });
//   }
//   return {
//     options: optionsMetadataArray,
//     selected: selectedMetadataObj
//   };

// }

export async function getSingleVideoDoc(video_id) {
  try {
    const videoDoc = await db.getVideoDoc(video_id)
    // TODO why do we need key? we already have id...
    return {...videoDoc, key : videoDoc.id };
  } catch (err) {
    return null;
  }
}

export async function getAllUserVideos(user_id) {
  return await db.getAllUserVideos(user_id)
}

export async function getVideosForSingleUser(user_id) {
  return await db.getVideosByUserId(user_id)
}

export async function deleteNotification(notif_id) {
  return await db.deleteNotification(notif_id);
}

// Just get their user doc, nothing else
export async function getUserByCognitoId(cognito_id) {
  const user = await db.getUserByCognitoId(cognito_id)
  if (user === undefined) {
    throw "Not a risk suite user"
  }
  return user;
}

// Just get their user doc, nothing else
export async function getUserById(id) {
  const user = await db.getUserById(id)
  if (user === undefined) {
    throw "Not a risk suite user"
  }
  user.uid = user.id;
  return user;
}

// this function should be deprecated, the role is now an
// attribute of the user object
export async function determineUserRole(id) {
  const user = await db.getUserById(id)
  return user.role
}

// TODO: use bcrypt
function generateAESKey() {
  var key = "";
  var hex = "0123456789abcdef";

  for (let i = 0; i < 32; i++) {
      key += hex.charAt(Math.floor(Math.random() * hex.length));
  }
  return key;
}

// Creates a new company doc
export async function createCompany(self_virgil_id, name) {
  const company_virgil_id = generatePushID();
  await createVirgilGroupWithSelf(self_virgil_id, company_virgil_id);
  console.log("Created group")
  // Generate AES key & use Virgil to obfuscate
  const aesKeySDID = await generateAESKey();
  const aesKeyHDID = await generateAESKey();
  const aesKeySD = await encryptMessage(generateAESKey());
  const aesKeyHD = await encryptMessage(generateAESKey());

  const company = await db.createCompany({
    name : name,
    aes_key: {[aesKeySDID]: aesKeySD, [aesKeyHDID]: aesKeyHD},
    meta_data: initial_company_meta_data,
    virgil_id: company_virgil_id
  })
  console.log("Company: " + JSON.stringify(company))
  return company;
}

export async function getGroup(group_id) {
  return await db.getGroup(group_id)
}

// Returns an array with the users groups
export async function getUsersGroups() {
  return await db.getUsersGroups().groups
}

// Writes to the user's document indicating that
// they are requesting to join the admin's company.
export async function joinCompanyRequest(company_name) {
  const company = await db.getCompanyByName(encodeURIComponent(company_name))
  return await db.requestUserCompanyJoin(company.id)
}

export async function getCompanyByName(company_name) {
  return await db.getCompanyByName(encodeURIComponent(company_name))
}

export async function doesEmailExist(email) {
  return await db.doesEmailExist(encodeURIComponent(email));
}

export async function doesCompanyNameExist(company_name) {
  return await db.doesCompanyNameExist(encodeURIComponent(company_name))
}

/*
 * updateRiskComponentsFirebaseHelpers
 * --------------------
 * A function meant to update risk assessment form data
 * that *is* gathered from video
 * -------
 * Params:
 *  - uid: The user who own's the video
 *  - videoId: The ID of the video to update
 *  - personId: The person within the video whose info needs to be updated
 *  - newInfo: A map that looks like this:
 *    newInfo["type"]: The type of the additional information
 *    newInfo["bodyGroup"]: The body part the information is about
 *    newInfo["newValue"]: The new value to set
 *
*/

export async function updateRiskComponentsFirebaseHelper(video_id, person_id, new_info) {
  await db.updateRiskComponents({video_id, person_id, new_info})
}

/*
 * updateAdditionalInfoFirebaseHelper
 * --------------------
 * A function meant to update risk assessment form data
 * that can't be gathered from video on Firebase
 * -------
 * Params:
 *  - uid: The user who own's the video
 *  - videoId: The ID of the video to update
 *  - personId: The person within the video whose info needs to be updated
 *  - newInfo: A map that looks like this:
 *    newInfo["type"]: The type of the additional information
 *    newInfo["bodyGroup"]: The body part the information is about
 *    newInfo["newValue"]: The new value to set
 *
*/
export async function updateAdditionalInfoFirebaseHelper(video_id, person_id, new_info) {
  await db.updateAdditionalVideoInfo({video_id, person_id, new_info})
}

// Create a new group within a company (not a virgil group, a firebase group)
// Accepts the list of UIDs of the people in the group & the new name of this group
export async function createNewGroupWithUsers(users, group_name) {
  const currentUserObj = getCurrentUser();
  const companyId = currentUserObj.company_id;
  const group = await db.createGroup(encodeURIComponent(group_name))

  for (let i = 0; i < users.length; i++){
    await db.addUserToGroup({user_id: users[i].id, group_id: group.id})
  }

  let newGroup = { userData: users, groupName: group_name, key: group.id }
	return newGroup;
}

export async function deleteGroup(group_id) {
  await db.deleteGroup(group_id)
}

export async function createUser(userFormObject) {
  // Creates a new user doc and sets the user's role. If you're creating the org,
  // you're an "admin", otherwise you're "requesting" access to the company.
  // The admin must grant access.
  let user = null
	try {
    const {name, email, companyName, isCreatingNewOrg} = userFormObject

    user = await db.createUser({name, email})
    if (! isCreatingNewOrg) {
      const company = await db.getCompanyByName(companyName)
      user = await db.requestUserCompanyJoin(company.id)
    }
    return user
	} catch (err) {
		console.log(err);
	}
}

// When a user requests to join a company there is a document
// written to the admin's 'requesting' collection. Once they accept
// the request this document can be deleted and the user's role
// can be updated
export async function acceptUserIntoCompanyFirebaseHelper(id) {
  await db.acceptUserCompanyJoin(id)
}

export async function denyUserRequest(id) {
  await db.denyUserCompanyJoin(id)
}

export async function getRequestingUsers() {
  return (await db.getRequestingUsers()).users
}

export async function getCompanysGroups() {
  return (await db.getCompanysGroups()).groups
}

export async function addUserToGroup(user_id, group_id) {
  return await db.addUserToGroup({user_id, group_id})
}

export async function removeUserFromGroup(user_id, group_id) {
  await db.removeUserFromGroup({user_id, group_id})
}

export async function getCompaniesGroups() {

  const group_ids = db.getGroupsByCompany().groups

  let userGroupObjs = [];
  group_ids.forEach( async (group_id) => {
    let group = await db.getGroup(group_id);
    userGroupObjs.push({ userData: group.users, groupName: group.name, key: group.id });
  })
  return userGroupObjs;
}


// call server to grab chunks if not
// TODO not implemented yet
export async function getVideoJsons(uid, videoId, personId, jointMetadata, chunk) {
  var jsons = {};
  const url = (await db.getVideoJointData(videoId, chunk)).url;
  
  const res = await fetch(url);
  const res_json = await res.json();
  for (var key in res_json[personId]["Joint_Angles_3D"]) {
    let individualJointMetadata = null;
    if (jointMetadata) {
      individualJointMetadata = jointMetadata[String(personId)]
    }
    jsons[key] = formatData(res_json[personId]["Joint_Angles_3D"][key], chunk, individualJointMetadata, key);
  }
  return jsons;
}

export async function deleteVideosFirebaseHelper(userIds, videoIds) {
  videoIds.forEach(async (video_id) => {
    try {
      await db.deleteVideo(video_id)
    } catch (err) {
      console.log("ERROR")
      console.log(err);
    }
  });
};

export async function getVideoManifest(video_id) {
  return await db.getVideoManifest(video_id)
}

export async function getThumbnail(video_id) {
  return await db.getThumbnail(video_id);
}

export async function getVideoSlice(video_id, res, slice) {
  return await db.getVideoSlice(video_id, res, slice)
}

export async function getStatVideoCount() { }

export async function auth() {
  return await AsyncStorage.getItem("userObj")
}
