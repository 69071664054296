import {
	GET_VIDEO_LIST_REQUEST,
	GET_VIDEO_SUCCESS,
	ALL_VIDEOS_LOADED,
	LOADING_VIDEOS_START,
	MODIFY_VIDEO_DOC,
	UPDATE_ADDITIONAL_INFO,
	INITIALIZE_INSPECTION_DATA,
	UPLOAD_ADDITIONAL_INFO,
	GET_JOINT_DATA,
	GET_JOINT_DATA_SUCCESS,
	UPLOAD_RISK_COMPONENTS,
	UPDATE_RISK_COMPONENTS,
	DELETE_VIDEOS_SUCCESS,
	DELETE_VIDEOS_REQUEST,
	ADD_METADATA_OPTION,
	DELETE_METADATA_OPTION,
	EDIT_METADATA_OPTION,
	SET_VIDEO_METADATA,
	GET_ALL_METADATA_FIELDS,
	GET_ALL_METADATA_FIELDS_SUCCESS,
	ADD_SINGLE_VIDEO_LISTENER,
	REMOVE_VIDEO_LISTENERS,
	GENERATE_REPORT_REQUEST,
	GENERATE_REPORT_SUCCESS,
	GENERATE_REPORT_ERROR,
	GET_THUMBNAIL_REQUEST,
	GET_THUMBNAIL_SUCCESS,
	SET_VIDEO_METADATA_SUCCESS,
	SUBMIT_VIDEO_FEEDBACK
} from '../actions'

/* 
 * uploadRiskComponents
 * ------------------------
 * Dispatch meant to save the updated risk cgetJointDataomponent info in Firebase
 * ------------------------
 * Parameters:
 * 	- videoId: The ID of the video you're updating info for
 *	- personId: The person whose info you're modifying
  */
export const uploadRiskComponents = (videoId, personId) => ({
	type: UPLOAD_RISK_COMPONENTS,
 	payload: { videoId, personId }
})

/* 
 * updateRiskComponents
 * ------------------------
 * Dispatch meant to modify the individual components of a risk assessment.
 * ------------------------
 * Parameters:
 * 	- videoId: The ID of the video you're updating info for
 *	- personId: The person whose info you're modifying
 *	- newInfo: A dictionary meant to codify the type of info your updating.
 *  	- newInfo["bodyGroup"]: The body part whose risk your modifying.
 *		- newInfo["type"]: The type of info your changing for that body part
 *		- newInfo["newValue"]: The new (numerical) value of that risk component.
 */

export const updateRiskComponents = (videoId, personId, newInfo) => ({
	type: UPDATE_RISK_COMPONENTS,
 	payload: { videoId, personId, newInfo }
})

export const uploadAdditionalInfo = (videoId, personId) => ({
  type: UPLOAD_ADDITIONAL_INFO,
  payload: { videoId, personId }
});

export const updateAdditionalInfo = (videoId, personId, newInfo) => ({
  type: UPDATE_ADDITIONAL_INFO,
  payload: { videoId, personId, newInfo }
});

export const initializeInspectionData = (videoId) => ({
	type: INITIALIZE_INSPECTION_DATA,
	payload: { videoId }
});

export const getVideoListRequest = (userId, companyId, groupId, history) => ({
  // History needed to logout user if something goes wrong in getting doc
  type: GET_VIDEO_LIST_REQUEST,
  payload: { userId, companyId, groupId, history }
});

export const addNewVideo = (videoObj) => ({
  type: GET_VIDEO_SUCCESS,
  payload: { videoObj }
});

export const deleteVideosSuccess = (videoIds) => ({
	type: DELETE_VIDEOS_SUCCESS,
	payload: { videoIds }
});

export const getThumbnailRequest = (videoId, remoteThumbnailLoc) => ({
	type: GET_THUMBNAIL_REQUEST,
	payload: { videoId, remoteThumbnailLoc }
});

export const getThumbnailSuccess = (videoId, realThumbnailLoc) => ({
	type: GET_THUMBNAIL_SUCCESS,
	payload: { videoId, realThumbnailLoc }
});

export const deleteVideosRequest = (userId, videoIds) => ({
	type: DELETE_VIDEOS_REQUEST,
	payload: { userId, videoIds }
});

export const addMetadataOption = (fieldId, optionName) => ({
	type: ADD_METADATA_OPTION,
	payload: { fieldId, optionName }
});

export const deleteMetadataOption = (companyId, fieldId, optionId) => ({
	type: DELETE_METADATA_OPTION,
	payload: { companyId, fieldId, optionId }
});

export const editMetadataOption = (optionId, optionName) => ({
	type: EDIT_METADATA_OPTION,
	payload: { optionId, optionName }
});

export const setVideoMetadataSuccess = (videoId, metadataObj) => ({
	type: SET_VIDEO_METADATA_SUCCESS,
	payload: { videoId, metadataObj }
})
export const setVideoMetadata = (videoId, fieldId, optionId) => ({
	type: SET_VIDEO_METADATA,
	payload: { videoId, fieldId, optionId }
});

export const getAllMetadataFields = (videoId) => ({
	type: GET_ALL_METADATA_FIELDS,
	payload: { videoId }
});

export const getAllMetadataFieldsSuccess = (data) => ({
	type: GET_ALL_METADATA_FIELDS_SUCCESS,
	payload: { data }
});

export const modifyVideoDoc = (newData) => ({
	type: MODIFY_VIDEO_DOC,
	payload: { newData }
});

export const getJointData = (videoId, personId, chunk) => ({
	type: GET_JOINT_DATA,
	payload: { videoId, personId, chunk }
})

export const getJointDataSuccess = (videoId, data, chunk) => ({
	type: GET_JOINT_DATA_SUCCESS,
	payload: { videoId, data, chunk }
})

export const loadingVideos = () => ({
  type: LOADING_VIDEOS_START,
  payload: {  }
});

export const addSingleVideoListener = (videoId) => ({
  type: ADD_SINGLE_VIDEO_LISTENER,
  payload: { videoId }
});

export const allVideosLoaded = () => ({
  type: ALL_VIDEOS_LOADED,
  payload: {  }
});

export const removeVideoListeners = () => ({
	type: REMOVE_VIDEO_LISTENERS,
	payload: { }
});

export const generateReportRequest = (videoId, subjectId) => ({
	type: GENERATE_REPORT_REQUEST,
	payload: { videoId, subjectId }
});

export const generateReportError = () => ({
	type: GENERATE_REPORT_ERROR,
	payload: { }
})

export const generateReportSuccess = () => ({
	type: GENERATE_REPORT_SUCCESS,
	payload: { }
});

export const submitVideoFeedback = (rating, feedbackText) => ({
	type: SUBMIT_VIDEO_FEEDBACK,
	payload: {rating, feedbackText }
});

/*
 * Some more info on "updateRiskComponents" and "uploadRiskComponents". These functions
 * are meant to modify the individual components of risk. The first step is to look at Firebase
 * to see the risk component store schema. 
 * 
 * For the Reba/Rula assessment, you'll notice that the risk components
 * are stored in a dictionary, with the first key noting the body part
 * and the second key noting a subtype of information for that body part. 
 * For example, riskAssessment.riskComponent.Trunk.Score is the 
 * overall risk rating of the trunk, which is equivalent to the 4 big trunk categories
 * the rula/reba risk form gives. If I want to update this Score with a new value, I'd call the
 * updateRiskComponents dispatch, as follows: 
 * 
 * this.props.updateRiskComponents(this.props.videoKey, this.props.personId, {
      bodyGroup: "Trunk",
      type: "Score",
      newValue: 4 // This is the new value
    }) 
 * If you wanted to set the "neck twist" risk category to true, just do:
 * this.props.updateRiskComponents(this.props.videoKey, this.props.personId, {
      bodyGroup: "Neck",
      type: "Twist",
      newValue: 1 // 1 = true
    }) 
 * To make sure this update is reflected on firebase, simply call:
 *
 * this.props.uploadRiskComponents(this.props.videoKey, this.props.personId);
 */
