import * as env from 'env-var';

import { COGNITO_CONFIG, initializeUserPools } from 'tumeke-database/Cognito';
import { setAPIEndpoint } from 'tumeke-database';

/* Deployment configuration */

// The forward slash has to be at the end of the url
// TODO: Find JS equivalent of Python os.path.join
export const FIREBASE_CONFIG = JSON.parse(env.get('REACT_APP_FIREBASE_CONFIG').required().convertFromBase64().asString());
export const RISK_SUITE_BUCKET = env.get('REACT_APP_RISK_SUITE_BUCKET').required().asString();
export const TUMEKE_API = env.get('REACT_APP_TUMEKE_API').required().asString();
export const TUMEKE_WEBSOCKET_API = env.get('REACT_APP_TUMEKE_WEBSOCKET_API').required().asString();
export const MAX_DEMO_VIDEOS = env.get('REACT_APP_MAX_DEMO_VIDEOS').required().asIntPositive();
export const VIDEO_DELETE_TIME = env.get('REACT_APP_VIDEO_DELETE_TIME').required().asIntPositive();
export const DEMO_VIDEO_UIDS = env.get('REACT_APP_DEMO_VIDEO_UIDS').required().asString();
export const DEMO_EMAIL = env.get('REACT_APP_DEMO_EMAIL').required().asString();
export const DEMO_PASSWORD = env.get('REACT_APP_DEMO_PASSWORD').required().asString();
export const DEMO_UID = env.get('REACT_APP_DEMO_UID').required().asString();
export const DEMO_CLEARKEY = JSON.parse(env.get('REACT_APP_DEMO_CLEARKEY').required().convertFromBase64().asString());

initializeUserPools(
	env.get('REACT_APP_COGNITO_POOL').required().asString(),
	env.get('REACT_APP_COGNITO_CLIENT_ID').required().asString()
);

setAPIEndpoint(TUMEKE_API);

console.log("API: " + TUMEKE_API)
/* PROD config */
// export const FIREBASE_CONFIG = {
//     apiKey: "AIzaSyD7yjrlmJyph3KQElKs84gcux9oziOJzbw",
//     authDomain: "tumekeapi.firebaseapp.com",
//     databaseURL: "https://tumekeapi.firebaseio.com",
//     projectId: "tumekeapi",
//     storageBucket: "tumekeapi.appspot.com",
//     messagingSenderId: "167469950474",
//     appId: "1:167469950474:web:026444e2dc1519c7b161f3"
// };

// export const TUMEKE_API = "https://user.tumeke.io/";
// export const MAX_DEMO_VIDEOS = 2;
// export const VIDEO_DELETE_TIME = 10;
// export const DEMO_VIDEO_UIDS = "b2384fb2-08b2-49f7-9baf-421b7c56b6f2,b8b88120-ab5e-4df3-bd76-afe2bde91ddf";
// export const DEMO_EMAIL = "demo_account@tumeke.io";
// export const DEMO_PASSWORD = "asdfasdf";
// export const DEMO_UID = "6Kns3J1GhJN48ZQgnsBnBuGvcW23";
// export const DEMO_CLEARKEY = {
// 	"552e3788efed7a175a031285ee146b99":"913cb361af8eb1656404b31ad5dd5770",
// 	"65cddc9c49d4f5edfc1221ee390171b0":"58a0d895a7334919efc78af67c1b9f56"
// }
