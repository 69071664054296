/* SETTINGS */
export const CHANGE_LOCALE = "CHANGE_LOCALE";

/* AUTH */
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_ERROR = "LOGIN_USER_ERROR";
export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_ERROR = "REGISTER_USER_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const SET_DECYPTED_AES_KEYS_REQUEST = "SET_DECYPTED_AES_KEYS_REQUEST";
export const SET_DECYPTED_AES_KEYS_SUCCESS = "SET_DECYPTED_AES_KEYS_SUCCESS";
export const SET_DECRYPTING_KEYS = "SET_DECRYPTING_KEYS";
export const DETERMINE_USER_ROLE_REQUEST = "DETERMINE_USER_ROLE_REQUEST";
export const DETERMINE_USER_ROLE_SUCCESS = "DETERMINE_USER_ROLE_SUCCESS";
export const ASK_FOR_PASSWORD_REQUEST = "ASK_FOR_PASSWORD_REQUEST";
export const ASK_FOR_PASSWORD_SHOW_MODAL = "ASK_FOR_PASSWORD_SHOW_MODAL";
export const CANCEL_ASK_PASSWORD = "CANCEL_ASK_PASSWORD";
export const ENTERED_PASSWORD_REQUEST = "ENTERED_PASSWORD_REQUEST";
export const ENTER_PASSWORD_ERROR = "ENTER_PASSWORD_ERROR";
export const CHECK_PASSWORD = "CHECK_PASSWORD";
export const VIRGIL_INIT_ERROR = "VIRGIL_INIT_ERROR";

/* MENU */
export const MENU_SET_CLASSNAMES = "MENU_SET_CLASSNAMES";
export const MENU_CONTAINER_ADD_CLASSNAME = "MENU_CONTAINER_ADD_CLASSNAME";
export const MENU_CLICK_MOBILE_MENU = "MENU_CLICK_MOBILE_MENU";
export const MENU_CHANGE_DEFAULT_CLASSES = "MENU_CHANGE_DEFAULT_CLASSES";
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  "MENU_CHANGE_HAS_SUB_ITEM_STATUS";

/* ADMIN PROFILE */
export const ACCEPT_USER_INTO_COMPANY_REQUEST = "ACCEPT_USER_INTO_COMPANY_REQUEST";
export const ACCEPT_USER_INTO_COMPANY_SUCCESS = "ACCEPT_USER_INTO_COMPANY_SUCCESS";
export const INITIALIZE_REQUEST_LIST_REQUEST = "INITIALIZE_REQUEST_LIST_REQUEST";
export const INITIALIZE_REQUEST_LIST_SUCCESS = "INITIALIZE_REQUEST_LIST_SUCCESS";
export const CREATE_NEW_GROUP_REQUEST = "CREATE_NEW_GROUP_REQUEST";
export const CREATE_NEW_GROUP_SUCCESS = "CREATE_NEW_GROUP_SUCCESS";
export const DELETE_GROUP_REQUEST = "DELETE_GROUP_REQUEST";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const INITIALIZE_GROUPS_LIST_REQUEST = "INITIALIZE_GROUPS_LIST_REQUEST";
export const INITIALIZE_GROUPS_LIST_SUCCESS = "INITIALIZE_GROUPS_LIST_SUCCESS";
export const MOVE_USER_TO_GROUP_REQUEST = "MOVE_USER_TO_GROUP_REQUEST";
export const MOVE_USER_TO_GROUP_SUCCESS = "MOVE_USER_TO_GROUP_SUCCESS";
export const ADD_USER_TO_GROUP_REQUEST = "ADD_USER_TO_GROUP_REQUEST";
export const ADD_USER_TO_GROUP_SUCCESS = "ADD_USER_TO_GROUP_SUCCESS";
export const DENY_USER_REQUEST = "DENY_USER_REQUEST";
export const DENY_USER_SUCCESS = "DENY_USER_SUCCESS";

/* NOTIFICATIONS */
export const ADD_NOTIFICATION_RECORD = "ADD_NOTIFICATION_RECORD";
export const ADD_NOTIFICATION_RECORD_SUCCESS = "ADD_NOTIFICATION_RECORD_SUCCESS";
export const DELETE_NOTIFICATION_RECORD = "DELETE_NOTIFICATION_RECORD";
export const START_NOTIFICATION_LISTENER = "START_NOTIFICATION_LISTENER";
export const SET_LISTENER_ACTIVE = "SET_LISTENER_ACTIVE";
export const REMOVE_NOTIFICATION_LISTENER = "REMOVE_NOTIFICATION_LISTENER";

/* VIDEO LIST */
export const GET_VIDEO_LIST_REQUEST = "GET_VIDEO_LIST_REQUEST";
export const GET_VIDEO_SUCCESS = "GET_VIDEO_SUCCESS";
export const ADD_SINGLE_VIDEO_LISTENER = "ADD_SINGLE_VIDEO_LISTENER";
export const MODIFY_VIDEO_DOC = "MODIFY_VIDEO_DOC"
export const LOADING_VIDEOS_START = "LOADING_VIDEOS_START"
export const ALL_VIDEOS_LOADED = "ALL_VIDEOS_LOADED"
export const UPDATE_ADDITIONAL_INFO = "UPDATE_ADDITIONAL_INFO";
export const INITIALIZE_INSPECTION_DATA = 'INITIALIZE_INSPECTION_DATA';
export const UPLOAD_ADDITIONAL_INFO = 'UPLOAD_ADDITIONAL_INFO';
export const GET_JOINT_DATA = "GET_JOINT_DATA";
export const GET_JOINT_DATA_SUCCESS = "GET_JOINT_DATA_SUCCESS";
export const UPDATE_RISK_COMPONENTS = "UPDATE_RISK_COMPONENTS";
export const UPLOAD_RISK_COMPONENTS = "UPLOAD_RISK_COMPONENTS";
export const DELETE_VIDEOS_REQUEST = "DELETE_VIDEOS_REQUEST";
export const DELETE_VIDEOS_SUCCESS = "DELETE_VIDEOS_SUCCESS";
export const ADD_METADATA_OPTION = "ADD_METADATA_OPTION";
export const DELETE_METADATA_OPTION = "DELETE_METADATA_OPTION";
export const EDIT_METADATA_OPTION = "EDIT_METADATA_OPTION";
export const SET_VIDEO_METADATA = "SET_VIDEO_METADATA";
export const SET_VIDEO_METADATA_SUCCESS = "SET_VIDEO_METADATA_SUCCESS";
export const SET_COMPANY_METADATA_SUCCESS = "SET_COMPANY_METADATA_SUCCESS";
export const GET_ALL_METADATA_FIELDS = "GET_ALL_METADATA_FIELDS";
export const GET_ALL_METADATA_FIELDS_SUCCESS = "GET_ALL_METADATA_FIELDS_SUCCESS";
export const REMOVE_VIDEO_LISTENERS = "REMOVE_VIDEO_LISTENERS";
export const GENERATE_REPORT_REQUEST = "GENERATE_REPORT_REQUEST";
export const GENERATE_REPORT_SUCCESS = "GENERATE_REPORT_SUCCESS";
export const GENERATE_REPORT_ERROR = "GENERATE_REPORT_ERROR";
export const GET_THUMBNAIL_REQUEST = "GET_THUMBNAIL_REQUEST";
export const GET_THUMBNAIL_SUCCESS = "GET_THUMBNAIL_SUCCESS";
export const SUBMIT_VIDEO_FEEDBACK = "SUBMIT_VIDEO_FEEDBACK";


export * from "./menu/actions";
export * from "./settings/actions";
export * from "./auth/actions";
export * from "./videos/actions";
export * from "./notifications/actions";
export * from "./adminProfile/actions";