export const RULAComponentRiskScore = {
	"Neck": {
		"1": {
			"ShortText": "Acceptable",
			"Color": "rgba(109, 208, 163, 1.0)"
		},
		"2": {
			"ShortText": "Low",
			"Color": "rgba(20, 210, 184, 1.0)"
		},
		"3":  {
			"ShortText": "Medium",
			"Color": "rgba(234, 130, 130, 1.0)"
		},
		"4":  {
			"ShortText": "Medium",
			"Color": "rgba(234, 130, 130, 1.0)"
		},
		"5":  {
			"ShortText": "High",
			"Color": "rgba(184, 15, 10, 1.0)"
		},
		"6": {
			"ShortText": "High",
			"Color": "rgba(184, 15, 10, 1.0)"
		}
	},
	"Upper Arm": {
		"1": {
			"ShortText": "Acceptable",
			"Color": "rgba(109, 208, 163, 1.0)"
		},
		"2": {
			"ShortText": "Low",
			"Color": "rgba(20, 210, 184, 1.0)"
		},
		"3":  {
			"ShortText": "Medium",
			"Color": "rgba(234, 130, 130, 1.0)"
		},
		"4":  {
			"ShortText": "Medium",
			"Color": "rgba(234, 130, 130, 1.0)"
		},
		"5":  {
			"ShortText": "High",
			"Color": "rgba(184, 15, 10, 1.0)"
		},
		"6": {
			"ShortText": "High",
			"Color": "rgba(184, 15, 10, 1.0)"
		}
	},
	"Lower Arm": {
		"1": {
			"ShortText": "Low",
			"Color": "rgba(20, 210, 184, 1.0)"
		},
		"2": {
			"ShortText": "Medium",
			"Color": "rgba(234, 130, 130, 1.0)"
		},
		"3": {
			"ShortText": "High",
			"Color": "rgba(184, 15, 10, 1.0)"
		},
	},
	"Trunk": {
		"1": {
			"ShortText": "Acceptable",
			"Color": "rgba(109, 208, 163, 1.0)"
		},
		"2": {
			"ShortText": "Low",
			"Color": "rgba(20, 210, 184, 1.0)"
		},
		"3":  {
			"ShortText": "Medium",
			"Color": "rgba(234, 130, 130, 1.0)"
		},
		"4":  {
			"ShortText": "Medium",
			"Color": "rgba(234, 130, 130, 1.0)"
		},
		"5":  {
			"ShortText": "High",
			"Color": "rgba(184, 15, 10, 1.0)"
		},
		"6": {
			"ShortText": "High",
			"Color": "rgba(184, 15, 10, 1.0)"
		}
	},
}

export const RULAComponentValues = {
    "Neck": {
        "Score": {
            "1": {
            	"Text": "0° - 10° forward",
            	"Warning": ""
            },
            "2": {
            	"Text": "10° - 20° forward",
            	"Warning": ""
            },
            "3": {
            	"Text": "20°+ forward",
            	"Warning": "Neck bent forward too much"
            },
            "4": {
            	"Text": "0°+ backward",
            	"Warning": "Neck bent backward"
            },
            "Base": 1
        },
        "SideBend": {
        	"1": {
        		"Text": "Yes",
        		"Warning": "Neck side bending"
        	},
        	"0": {
        		"Text": "No",
        		"Warning": ""
        	},
            "Base": 0
        },
        "Twist": {
        	"1": {
        		"Text": "Yes",
        		"Warning": "Neck twisted"
        	},
        	"0": {
        		"Text": "No",
        		"Warning": ""
        	},
            "Base": 0
        },
        "Image": '/assets/img/bodyParts/neck.png'
    },
    "Upper Arm": {
        "Score": {
            "1": {
            	"Text": "20° in front - 20° behind",
            	"Warning": ""
            },
            "2": {
            	"Text": "20°+ behind OR 20° - 45° in front",
            	"Warning": ""
            },
            "3": {
            	"Text": "45° - 90° in front",
            	"Warning": "Upper arm raised high"
            },
            "4": {
            	"Text": "90°+ in front",
            	"Warning": "Upper arm raised too high"
            },
            "Base": 1
        },
        "Abducted": {
        	"1": {
        		"Text": "Yes",
        		"Warning": "Shoulder abducted"
        	},
        	"0": {
        		"Text": "No",
        		"Warning": ""
        	},
            "Base": 0
        },
        "ShoulderRaised": {
        	"1": {
        		"Text": "Yes",
        		"Warning": ""
        	},
        	"0": {
        		"Text": "No",
        		"Warning": ""
        	},
            "Base": 0
        },
        "Image": '/assets/img/bodyParts/shoulder.png'
    },
    "Trunk": {
        "Score": {
            "1": {
            	"Text": "0°",
            	"Warning": ""
            },
            "2": {
            	"Text": "0° - 20° forward",
            	"Warning": ""
            },
            "3": {
            	"Text": "20° - 60° forward",
            	"Warning": "Trunk bent forward too much"
            },
            "4": {
            	"Text": "60°+ forward",
            	"Warning": "Trunk bent forward too much"
            },
            "Base": 1
        },
        "SideBend": {
        	"1": {
        		"Text": "Yes",
        		"Warning": "Trunk side bending"
        	},
        	"0": {
        		"Text": "No",
        		"Warning": ""
        	},
            "Base": 0
        },
        "Twist": {
        	"1": {
        		"Text": "Yes",
        		"Warning": "Trunk twisted"
        	},
        	"0": {
        		"Text": "No",
        		"Warning": ""
        	},
            "Base": 0
        },
        "Image": '/assets/img/bodyParts/back.png'
    },
    "Lower Arm": {
        "Score": {
            "1": {
            	"Text": "60° - 100°",
            	"Warning": ""
            },
            "2": {
            	"Text": "0° - 60° OR 100°+",
            	"Warning": ""
            }
        },
        "Midline": {
        	"1": {
        		"Text": "Yes",
        		"Warning": ""
        	},
        	"0": {
        		"Text": "No",
        		"Warning": ""
        	}
        },
        "Image": '/assets/img/bodyParts/arm.png'
    },
    "Wrist": {
        "Score": {
            "1": {
            	"Text": "0°",
            	"Warning": ""
            },
            "2": {
            	"Text": "15° up - 15° down",
            	"Warning": "Wrist bent"
            },
            "3": {
            	"Text": "15°+ up OR 15°+ down",
            	"Warning": "Wrist far too bent"
            }
        },
        "Image": '/assets/img/bodyParts/wrist.png'
    }
};

export const REBAComponentValues = {
    "Neck": {
        "Score": {
            "1": {
                "Text": "0° - 20° forward",
                "Warning": ""
            },
            "2": {
                "Text": "20°+ forward",
                "Warning": "Neck bent forward"
            },
            "3": {
                "Text": "0°+ backward",
                "Warning": "Neck bent backward"
            },
            "Base": 1
        },
        "SideBend": {
            "1": {
                "Text": "Yes",
                "Warning": "Neck side bending"
            },
            "0": {
                "Text": "No",
                "Warning": ""
            },
            "Base": 0
        },
        "Twist": {
            "1": {
                "Text": "Yes",
                "Warning": "Neck twisted"
            },
            "0": {
                "Text": "No",
                "Warning": ""
            },
            "Base": 0
        },
        "Image": '/assets/img/bodyParts/neck.png'
    },
    "Upper Arm": {
        "Score": {
            "1": {
                "Text": "20° in front - 20° behind",
                "Warning": ""
            },
            "2": {
                "Text": "20°+ behind OR 20° - 45° in front",
                "Warning": "Upper arm bent slightly"
            },
            "3": {
                "Text": "45° - 90° in front",
                "Warning": "Upper arm bent forward"
            },
            "4": {
                "Text": "90°+ in front",
                "Warning": "Upper arm bent very forward"
            },
            "Base": 1
        },
        "Abducted": {
            "1": {
                "Text": "Yes",
                "Warning": "Shoulder abducted"
            },
            "0": {
                "Text": "No",
                "Warning": ""
            },
            "Base": 0
        },
        "ShoulderRaised": {
            "1": {
                "Text": "Yes",
                "Warning": "Shoulders raised"
            },
            "0": {
                "Text": "No",
                "Warning": ""
            },
            "Base": 0
        },
        "Image": '/assets/img/bodyParts/shoulder.png'
    },
    "Trunk": {
        "Score": {
            "1": {
                "Text": "0°",
                "Warning": ""
            },
            "2": {
                "Text": "0° - 20° forward",
                "Warning": ""
            },
            "3": {
                "Text": "20° - 60° forward",
                "Warning": "Trunk bent forward too much"
            },
            "4": {
                "Text": "60°+ forward",
                "Warning": "Trunk bent forward too much"
            },
            "Base": 1
        },
        "SideBend": {
            "1": {
                "Text": "Yes",
                "Warning": "Trunk side bending"
            },
            "0": {
                "Text": "No",
                "Warning": ""
            },
            "Base": 0
        },
        "Twist": {
            "1": {
                "Text": "Yes",
                "Warning": "Trunk twisted"
            },
            "0": {
                "Text": "No",
                "Warning": ""
            },
            "Base": 0
        },
        "Image": '/assets/img/bodyParts/back.png'
    },
    "Lower Arm": {
        "Score": {
            "1": {
                "Text": "60° - 100°",
                "Warning": ""
            },
            "2": {
                "Text": "0° - 60° OR 100°+",
                "Warning": "Lower arm not in neutral 90° position"
            },
            "Base": 1
        },
        "Image": '/assets/img/bodyParts/arm.png'
    },
    "Wrist": {
       "Score": {
            "1": {
                "Text": "15° up - 15° down",
                "Warning": ""
            },
            "2": {
                "Text": "15°+ up OR 15°+ down",
                "Warning": "Wrist far too bent"
            },
            "Base": 1
        },
        "Image": '/assets/img/bodyParts/wrist.png'
    },
    "Leg": {
        "Score": {
            "1": {
                "Text": "0",
                "Warning": ""
            },
            "2": {
                "Text": "30° - 60°",
                "Warning": "Legs bent"
            },
             "3": {
                "Text": "60°+",
                "Warning": "Legs bent too much"
            },
            "Base": 1
        },
        "FirmlyPlanted": {
            "1": {
                "Text": "Yes",
                "Warning": ""
            },
            "0": {
                "Text": "No",
                "Warning": ""
            },
            "Base": 0
        },
        "Image": '/assets/img/bodyParts/leg.png'
    }
};

export const REBAComponentRiskScore = {
    "Neck": {
        "1": {
            "ShortText": "Acceptable",
            "Color": "rgba(109, 208, 163, 1.0)"
        },
        "2": {
            "ShortText": "Low",
            "Color": "rgba(20, 210, 184, 1.0)"
        },
        "3":  {
            "ShortText": "Medium",
            "Color": "rgba(234, 130, 130, 1.0)"
        },
        "4":  {
            "ShortText": "Medium",
            "Color": "rgba(234, 130, 130, 1.0)"
        },
        "5":  {
            "ShortText": "High",
            "Color": "rgba(184, 15, 10, 1.0)"
        },
        "6": {
            "ShortText": "High",
            "Color": "rgba(184, 15, 10, 1.0)"
        }
    },
    "Upper Arm": {
        "1": {
            "ShortText": "Acceptable",
            "Color": "rgba(109, 208, 163, 1.0)"
        },
        "2": {
            "ShortText": "Low",
            "Color": "rgba(20, 210, 184, 1.0)"
        },
        "3":  {
            "ShortText": "Medium",
            "Color": "rgba(234, 130, 130, 1.0)"
        },
        "4":  {
            "ShortText": "Medium",
            "Color": "rgba(234, 130, 130, 1.0)"
        },
        "5":  {
            "ShortText": "High",
            "Color": "rgba(184, 15, 10, 1.0)"
        },
        "6": {
            "ShortText": "High",
            "Color": "rgba(184, 15, 10, 1.0)"
        }
    },
    "Lower Arm": {
        "1": {
            "ShortText": "Low",
            "Color": "rgba(20, 210, 184, 1.0)"
        },
        "2": {
            "ShortText": "Medium",
            "Color": "rgba(234, 130, 130, 1.0)"
        },
    },
    "Trunk": {
        "1": {
            "ShortText": "Acceptable",
            "Color": "rgba(109, 208, 163, 1.0)"
        },
        "2": {
            "ShortText": "Low",
            "Color": "rgba(20, 210, 184, 1.0)"
        },
        "3":  {
            "ShortText": "Medium",
            "Color": "rgba(234, 130, 130, 1.0)"
        },
        "4":  {
            "ShortText": "Medium",
            "Color": "rgba(234, 130, 130, 1.0)"
        },
        "5":  {
            "ShortText": "High",
            "Color": "rgba(184, 15, 10, 1.0)"
        },
        "6": {
            "ShortText": "High",
            "Color": "rgba(184, 15, 10, 1.0)"
        }
    },
    "Leg": {
        "1": {
            "ShortText": "Acceptable",
            "Color": "rgba(109, 208, 163, 1.0)"
        },
        "2": {
            "ShortText": "Low",
            "Color": "rgba(20, 210, 184, 1.0)"
        },
        "3":  {
            "ShortText": "Medium",
            "Color": "rgba(234, 130, 130, 1.0)"
        },
        "4":  {
            "ShortText": "High",
            "Color": "rgba(184, 15, 10, 1.0)"
        },
    }
}