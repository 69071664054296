import { all, call, fork, put, takeEvery, select, take } from 'redux-saga/effects';
import { eventChannel } from "redux-saga"

import {
    ADD_NOTIFICATION_RECORD,
    ADD_NOTIFICATION_RECORD_SUCCESS,
    DELETE_NOTIFICATION_RECORD,
    START_NOTIFICATION_LISTENER,
    REMOVE_NOTIFICATION_LISTENER
} from '../actions'

import {
    addNotificationRecord,
    addNotificationRecordSuccess,
    deleteNotificationRecord,
    setListenerActive,
} from './actions';

import {
  deleteNotification
} from '../../helpers/DatabaseHelpers'

import {
  TUMEKE_WEBSOCKET_API
} from '../../constants/config'

export function* startNotificationListenerWatch() {
    yield takeEvery(START_NOTIFICATION_LISTENER, startNotificationListenerSaga);
}

export function* deleteNotificationRecordWatch() {
    yield takeEvery(DELETE_NOTIFICATION_RECORD, deleteNotificationRecordSaga);
}

const registerNotificationsListener = (userId) => {
  return eventChannel(emit => {
    const handleNewNotification = (notifDocs) => {

      
      for (let i = 0; i < notifDocs.length; i++) {
        const doc = {
          ...notifDocs[i],
          ...notifDocs[i]["data"]
        }
        emit({action: "ADD", data: doc, key: notifDocs[i]["id"]});
      }
      
      
    }
    const channel = new WebSocket(TUMEKE_WEBSOCKET_API+'/registerNotificationListener/'+userId);
    channel.onmessage = (event) => {
      const resp = JSON.parse(event.data);
      console.log("NOTIF RESP: " + event.data)
      if ("message" in resp) {
        if (resp["message"] === "Bad auth") {
          channel.close();
          return;
        }

      }
      if ("data" in resp) {
        handleNewNotification(resp["data"])
      }
    };
    
    channel.onclose = () => {
      console.log("Channel closed")
    }
    channel.onopen = () => {
      const id_token = localStorage.getItem("id_token")
      channel.send(id_token);
    }
    
    const unsubscribe = () => {
      channel.close()
    }
    return unsubscribe;
  })
}

function* closeNotificationChannel(listener) {
  while (true) {
    yield take (REMOVE_NOTIFICATION_LISTENER);
    listener.close();
  }
}

function* deleteNotificationRecordSaga ({ payload }) {
  console.log(JSON.stringify(payload))
  yield call(deleteNotification, payload.notificationId)
}

function* startNotificationListenerSaga({ payload }) {
    const state = yield select();
    if (state.notifications.listenerActive) {
        return;
    }
    yield put(setListenerActive());
    console.log("Starting notification listener saga")
    const user_id = state.authUser.user.id;
    const listener = registerNotificationsListener(user_id);
    yield fork(closeNotificationChannel, listener);
    while (true) {
    const state = yield select();
    try {
      const payload = yield take(listener);
      if (payload.action === "ADD") {
        yield put(addNotificationRecord(payload.data, payload.key));
      }

    } catch (err) {
      console.log("Error: " + err);
    }
  }
}

export default function* rootSaga() {
    yield all([
        fork(startNotificationListenerWatch),
        fork(deleteNotificationRecordWatch)
    ]);
}
