import {
    ADD_NOTIFICATION_RECORD,
    ADD_NOTIFICATION_RECORD_SUCCESS,
    DELETE_NOTIFICATION_RECORD,
    START_NOTIFICATION_LISTENER,
    SET_LISTENER_ACTIVE,
    
} from '../actions'

const INIT_STATE = {
    notificationList: [],
    listenerActive: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_LISTENER_ACTIVE:
            return {...state, listenerActive: true}
        case START_NOTIFICATION_LISTENER:
            return {...state}
        case ADD_NOTIFICATION_RECORD:
            return { ...state, notificationList: [...state.notificationList, {
                ...action.payload.notification,
                key: action.payload.key
            }]};
        case DELETE_NOTIFICATION_RECORD:
            console.log("IN REDUCER FOR DELETE notification")
            return {...state, notificationList: state.notificationList.filter((notification) => {
                return notification.key !== action.payload.notificationId
            }) };

        default: return { ...state };
    }
}
