export const SAMPLE_SIZE_SECONDS = 2

export const FM_FREQUENCIES = [
  0, .2, .5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15
]

export const FM_SHORT_DURATION = [
  1.0, 1.0, .97, .91, .88, .84, .80, .75, .70, .60, .52, .45, .41, .37, .34, .31, .28, 0
]

export const FM_MODERATE_DURATION = [
  .95, .95, .92, .88, .84, .79, .72, .60, .50, .42, .35, .30, .26, .23, .21, 0, 0, 0
]

export const FM_LONG_DURATION = [
  .85, .81, .75, .65, .55, .45, .35, .27, .22, .18, .15, .13, 0, 0, 0, 0, 0, 0
]


export const FM_SHORT_DURATION_VERTICAL_CUTOFF = 13

export const FM_MODERATE_DURATION_VERTICAL_CUTOFF = 10

export const FM_LONG_DURATION_VERTICAL_CUTOFF = 8

export const NIOSHComponentValues = {
	"hm" : {
		"[0, .4]" : {
			"Text": "Poor",
			"ShortText": "Poor",
			"Color": "rgba(184, 15, 10, 1.0)",
			"Warning": "Object too far away horizontally"
		},
		"[.4, .6]" : {
			"Text": "Fair",
			"ShortText": "Fair",
			"Color": "rgba(234, 130, 130, 1.0)",
			"Warning": "Object too far away horizontally"
		},
		"[.6, 1.0]" : {
			"Text": "Good",
			"ShortText": "Good",
			"Color": "rgba(109, 208, 163, 1.0)",
			"Warning": ""
		}
	},
	"vm" : {
		"[0, .8]" : {
			"Text": "Poor",
			"ShortText": "Poor",
			"Color": "rgba(184, 15, 10, 1.0)",
			"Warning": "Object begins too far away vertically"
		},
		"[.8, .9]" : {
			"Text": "Fair",
			"ShortText": "Fair",
			"Color": "rgba(234, 130, 130, 1.0)",
			"Warning": ""
		},
		"[.9, 1.0]" : {
			"Text": "Good",
			"ShortText": "Good",
			"Color": "rgba(109, 208, 163, 1.0)",
			"Warning": ""
		}
	},
	"dm" : {
		"[0, .9]" : {
			"Text": "Poor",
			"ShortText": "Poor",
			"Color": "rgba(184, 15, 10, 1.0)",
			"Warning": "Object travels too far"
		},
		"[.9, .95]" : {
			"Text": "Fair",
			"ShortText": "Fair",
			"Color": "rgba(234, 130, 130, 1.0)",
			"Warning": ""
		},
		"[.95, 1.0]" : {
			"Text": "Good",
			"ShortText": "Good",
			"Color": "rgba(109, 208, 163, 1.0)",
			"Warning": ""
		}
	},
	"am" : {
		"[0, .7]" : {
			"Text": "Poor",
			"ShortText": "Poor",
			"Color": "rgba(184, 15, 10, 1.0)",
			"Warning": "Person twists too much"
		},
		"[.7, .9]" : {
			"Text": "Fair",
			"ShortText": "Fair",
			"Color": "rgba(234, 130, 130, 1.0)",
			"Warning": ""
		},
		"[.9, 1.0]" : {
			"Text": "Good",
			"ShortText": "Good",
			"Color": "rgba(109, 208, 163, 1.0)",
			"Warning": ""
		}
	},
	"fm" : {
		"[0, .45]" : {
			"Text": "Poor",
			"ShortText": "Poor",
			"Color": "rgba(184, 15, 10, 1.0)",
			"Warning": "Person twists too much"
		},
		"[.45, .8]" : {
			"Text": "Fair",
			"ShortText": "Fair",
			"Color": "rgba(234, 130, 130, 1.0)",
			"Warning": ""
		},
		"[.8, 1.0]" : {
			"Text": "Good",
			"ShortText": "Good",
			"Color": "rgba(109, 208, 163, 1.0)",
			"Warning": ""
		}
	}
}

export const NIOSHRiskValues = {
	"li" : {
		"[0, 1.5]" : {
			"Text": "Safe weight to lift",
			"ShortText": "Good",
			"Color": "rgba(109, 208, 163, 1.0)",
			"Warning": ""
		},
		"[1.5, 2.5]" : {
			"Text": "Weight moderately heavy",
			"ShortText": "Fair",
			"Color": "rgba(234, 130, 130, 1.0)",
			"Warning": "Object heavy"
		},
		"[2.5, 10.0]" : {
			"Text": "Weight heavy for given task",
			"ShortText": "Poor",
			"Color": "rgba(184, 15, 10, 1.0)",
			"Warning": "Object far too heavy"
		}
	}
}
export const CouplingStatus = {
	GOOD: 0,
	FAIR: 1,
	POOR: 2
}

export const DurationStatus = {
    SHORT: 0,
    MODERATE: 1,
    LONG: 2
}

export const HeightConstantsStandard = {
    LESS_FOUR: 0,
    FOUR_TO_FOUR_HALF: 1,
    FOUR_HALF_TO_FIVE: 2,
    FIVE_TO_FIVE_HALF: 3,
    FIVE_HALF_TO_SIX: 4,
    SIX_TO_SIX_HALF: 5,
    SIX_HALF_PLUS: 6
}

export const Units = {
	STANDARD: 0,
	METRIC: 1
}