import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import {
	acceptUserIntoCompanyFirebaseHelper,
	getRequestingUsers,
	getUsersGroups,
	createNewGroupWithUsers,
	deleteGroup,
	addUserToGroup,
	removeUserFromGroup,
	denyUserRequest,
	getCompanysGroups
} from '../../helpers/DatabaseHelpers';
import {  } from '../../helpers/VirgilHelpers';

import { initializeVirgil, getVirgilPrivateKey,
         acceptUserIntoCompanyVirgilHelper, joinGroup,
         isVirgilInitialized } from '../../helpers/VirgilHelpers';


import {
	ACCEPT_USER_INTO_COMPANY_REQUEST,
	INITIALIZE_REQUEST_LIST_REQUEST,
	INITIALIZE_GROUPS_LIST_REQUEST,
    CREATE_NEW_GROUP_REQUEST,
	DELETE_GROUP_REQUEST,
	MOVE_USER_TO_GROUP_REQUEST,
	ADD_USER_TO_GROUP_REQUEST,
	DENY_USER_REQUEST,
} from '../actions'

import {
	acceptUserIntoCompanySuccess,
	initializeRequestListSuccess,
	initializeGroupsListSuccess,
	createNewGroupSuccess,
	deleteGroupSuccess,
	moveUserToGroupSuccess,
	addUserToGroupSuccess,
	denyUserSuccess,
} from './actions'

export function* watchAcceptIntoCompany() {
    yield takeEvery(ACCEPT_USER_INTO_COMPANY_REQUEST, acceptUserIntoCompanySagaHelper);
}

export function* watchInitializeRequestList() {
    yield takeEvery(INITIALIZE_REQUEST_LIST_REQUEST, initializeRequestListHelper);
}

export function* watchCreateNewGroup() {
	yield takeEvery(CREATE_NEW_GROUP_REQUEST, createNewGroupSagaHelper);
}

export function* watchDeleteGroup() {
	yield takeEvery(DELETE_GROUP_REQUEST, deleteGroupSagaHelper);
}

export function* watchInitializeGroupsList() {
	yield takeEvery(INITIALIZE_GROUPS_LIST_REQUEST, initializeGroupsListHelper);
}

export function* watchMoveUserToGroupRequest() {
	yield takeEvery(MOVE_USER_TO_GROUP_REQUEST, moveUserToGroupHelper);
}

export function* watchAddUserToGroupRequest() {
	yield takeEvery(ADD_USER_TO_GROUP_REQUEST, addUserToGroupHelper);
}

export function* watchDenyUserRequest() {
	yield takeEvery(DENY_USER_REQUEST, denyUserHelper);
}

function* acceptUserIntoCompanySagaHelper({ payload }) {
	const state = yield select();

	if (!isVirgilInitialized()) {
		console.log("not initialized")
    yield call(initializeVirgil);
    yield call(getVirgilPrivateKey, "", false);
    yield call(joinGroup, state.authUser.user, state.authUser.company);
    }
	
	console.log("VIRGIL ID: " + payload.user.virgil_id)
	yield call (acceptUserIntoCompanyVirgilHelper, payload.user.virgil_id);
	yield call (acceptUserIntoCompanyFirebaseHelper, payload.user.id);
	

	
	yield put (acceptUserIntoCompanySuccess(payload.user.id));
}

function* initializeRequestListHelper({ payload }) {
	const requestList = (yield call (getRequestingUsers))
	yield put(initializeRequestListSuccess(requestList));
}

function* createNewGroupSagaHelper({ payload }) {
	yield call (createNewGroupWithUsers, payload.users, payload.groupName);
	const userGroups = yield call (getCompanysGroups);
	yield put(createNewGroupSuccess(userGroups));
}

function* deleteGroupSagaHelper({ payload }) {
	yield put(deleteGroupSuccess(payload.groupId));
	yield call (deleteGroup, payload.groupId);
}

function* initializeGroupsListHelper({ payload }) {
	const userGroups = yield call (getCompanysGroups);
	console.log("GROUPS: " + userGroups);
	yield put(initializeGroupsListSuccess(userGroups));
}

function* moveUserToGroupHelper({ payload }) {
	
	yield call (addUserToGroup, payload.userId, payload.toGroup);
	yield call (removeUserFromGroup, payload.userId, payload.fromGroup);
	yield put (moveUserToGroupSuccess(payload.userId, payload.toGroup, payload.fromGroup));
}

function* addUserToGroupHelper({ payload }) {
	yield call (addUserToGroup, payload.user.id, payload.groupId);
	const userGroups = yield call (getCompanysGroups);
	yield put (addUserToGroupSuccess(userGroups));
}

function* denyUserHelper({ payload }) {
	yield call(denyUserRequest, payload.userId);
	yield put (denyUserSuccess(payload.userId));
}

export default function* rootSaga() {
    yield all([
        fork(watchAcceptIntoCompany),
				fork(watchInitializeRequestList),
				fork(watchCreateNewGroup),
				fork(watchDeleteGroup),
				fork(watchInitializeGroupsList),
				fork(watchMoveUserToGroupRequest),
				fork(watchAddUserToGroupRequest),
				fork(watchDenyUserRequest),
    ]);
}
