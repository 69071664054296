import mixpanel from 'mixpanel-browser';
mixpanel.init('42992e7d018f423529c62f0e5322238b');

let env_check = process.env.NODE_ENV === 'production';
// let env_check = true;

let actions = {
    identify: (id) => {
        if (env_check) mixpanel.identify(id);
    },
    alias: (id) => {
        if (env_check) mixpanel.alias(id);
    },
    track: (name, props) => {
        if (env_check) mixpanel.track(name, props);
    },
    people: {
        set: (props) => {
            if (env_check) mixpanel.people.set(props);
        },
    },
    reset: () => {
        if (env_check) mixpanel.reset();
    }
};

export let Mixpanel = actions;