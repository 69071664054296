import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { configureStore } from './redux/store';
import * as Sentry from '@sentry/browser';

Sentry.init({dsn: "https://7af29fec0afa48b39cc8b1b55ad7b024@o400209.ingest.sentry.io/5283402"});

const App = React.lazy(() => import(/* webpackChunkName: "App" */'./App' ));

// get the Version of Browser, older than '11'
const getIEVersion = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ');
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
  }
  const trident = ua.indexOf('Trident/');
  if (trident > 0) {
    // IE 11 => return version number
    const rv = ua.indexOf('rv:');
    return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
  }
  const edge = ua.indexOf('Edge/');
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
  }
  // other browser
  return false;
};

// Method for Rendering of Unsupported Version
const UnsupportedBrowserPage = () => (
  <div>
    <p>Hello there! TuMeke uses advanced encryption to protect user data that is not available on Internet Explorer</p>
  	<p>We support all other major browsers, including Chrome, Firefox, Safari, and Opera</p>
  </div>
);


const iEVersion = getIEVersion();

if (iEVersion && iEVersion <= 11) {
  ReactDOM.render(<UnsupportedBrowserPage />, document.getElementById('root'));
} else {
  ReactDOM.render(
    <Provider store={configureStore()}>
      <Suspense fallback={<div className="loading" />}>
        <App />
      </Suspense>
    </Provider>,
    document.getElementById('root')
  );
  /*
   * If you want your app to work offline and load faster, you can change
   * unregister() to register() below. Note this comes with some pitfalls.
   * Learn more about service workers: https://bit.ly/CRA-PWA
   */
  serviceWorker.unregister();
}


