import {
	ACCEPT_USER_INTO_COMPANY_REQUEST,
	ACCEPT_USER_INTO_COMPANY_SUCCESS,
	INITIALIZE_REQUEST_LIST_REQUEST,
  INITIALIZE_REQUEST_LIST_SUCCESS,
  CREATE_NEW_GROUP_REQUEST,
  CREATE_NEW_GROUP_SUCCESS,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  INITIALIZE_GROUPS_LIST_REQUEST,
  INITIALIZE_GROUPS_LIST_SUCCESS,
  MOVE_USER_TO_GROUP_REQUEST,
  MOVE_USER_TO_GROUP_SUCCESS,
  ADD_USER_TO_GROUP_REQUEST,
  ADD_USER_TO_GROUP_SUCCESS,
  DENY_USER_REQUEST,
  DENY_USER_SUCCESS,
} from '../actions'

export const acceptUserIntoCompanyRequest = (user) => ({
  type: ACCEPT_USER_INTO_COMPANY_REQUEST,
  payload: { user }
});

export const acceptUserIntoCompanySuccess = (userId) => ({
  type: ACCEPT_USER_INTO_COMPANY_SUCCESS,
  payload: { userId }
});

export const initializeRequestListRequest = () => ({
  type: INITIALIZE_REQUEST_LIST_REQUEST,
  payload: { }
})

export const initializeRequestListSuccess = (requestList) => ({
  type: INITIALIZE_REQUEST_LIST_SUCCESS,
  payload: { requestList }
})

export const initializeGroupsListRequest = () => ({
  type: INITIALIZE_GROUPS_LIST_REQUEST,
  payload: { }
})

export const initializeGroupsListSuccess = (userGroups) => ({
  type: INITIALIZE_GROUPS_LIST_SUCCESS,
  payload: { userGroups }
})

export const createNewGroupRequest = (users, groupName) => ({
  type: CREATE_NEW_GROUP_REQUEST,
  payload: { users, groupName }
})

export const createNewGroupSuccess = (userGroups) => ({
  type: CREATE_NEW_GROUP_SUCCESS,
  payload: { userGroups }
})

export const deleteGroupRequest = (groupId) => ({
  type: DELETE_GROUP_REQUEST,
  payload: { groupId }
})

export const deleteGroupSuccess = (groupId) => ({
  type: DELETE_GROUP_SUCCESS,
  payload: { groupId }
})

export const moveUserToGroupRequest = (userId, toGroup, fromGroup) => ({
  type: MOVE_USER_TO_GROUP_REQUEST,
  payload: { userId, toGroup, fromGroup }
})

export const moveUserToGroupSuccess = (id, toGroup, fromGroup) => ({
  type: MOVE_USER_TO_GROUP_SUCCESS,
  payload: { id, toGroup, fromGroup }
})

export const addUserToGroupRequest = (user, groupId) => ({
  type: ADD_USER_TO_GROUP_REQUEST,
  payload: { user, groupId }
})

export const addUserToGroupSuccess = (userGroups) => ({
  type: ADD_USER_TO_GROUP_SUCCESS,
  payload: { userGroups }
})

export const denyUserRequest = (userId) => ({
  type: DENY_USER_REQUEST,
  payload: { userId }
})

export const denyUserSuccess = (userId) => ({
  type: DENY_USER_SUCCESS,
  payload: { userId }
})
  
