import _ from 'lodash'

import {
    GET_VIDEO_LIST_REQUEST,
    GET_VIDEO_SUCCESS,
    MODIFY_VIDEO_DOC,
    ALL_VIDEOS_LOADED,
    LOADING_VIDEOS_START,
    UPDATE_ADDITIONAL_INFO,
    INITIALIZE_INSPECTION_DATA,
    GET_JOINT_DATA_SUCCESS,
    UPDATE_RISK_COMPONENTS,
    ADD_METADATA_OPTION,
    DELETE_METADATA_OPTION,
    EDIT_METADATA_OPTION,
    SET_VIDEO_METADATA,
    GET_ALL_METADATA_FIELDS,
    GET_ALL_METADATA_FIELDS_SUCCESS,
    DELETE_VIDEOS_SUCCESS,
    GENERATE_REPORT_REQUEST,
    GENERATE_REPORT_SUCCESS,
    GENERATE_REPORT_ERROR,
    GET_THUMBNAIL_SUCCESS,
    SUBMIT_VIDEO_FEEDBACK,
    SET_VIDEO_METADATA_SUCCESS
} from '../actions'

import { Rula } from "../../helpers/assessments/Rula";
import { Reba } from "../../helpers/assessments/Reba";
import { Niosh } from "../../helpers/assessments/Niosh";
import { Assessments } from "../../constants/maps";

const INIT_STATE = {
  videoList: {},
  videosLoaded: false,
  videosLoading: false,
  refresh: false,
  optionsMetadataArray: [],
  selectedMetadataObj: {},
  reportLoading: false
};



export default (state = INIT_STATE, action) => {
  switch (action.type) {
  
    case GET_VIDEO_SUCCESS:
      // This means listener has already been initialized, and the user is interacting
      // with the video in the GUI. In this case don't accept videos from snapshot listener
      const videoId = action.payload.videoObj.key;
      if (state.videoList[videoId] && 
        state.videoList[videoId].riskAssessment &&
        state.videoList[videoId].riskAssessment['0'].hasOwnProperty("updateAdditionalInfo")) {
          const newVideoObj = action.payload.videoObj;
          let additionsToObj = {}
          if (newVideoObj.hasOwnProperty("videoLoc")) {
            additionsToObj["videoLoc"] = newVideoObj.videoLoc;
          }
          if (newVideoObj.hasOwnProperty("bucketName")) {
            additionsToObj["bucketName"] = newVideoObj.bucketName;
          }
          if (newVideoObj.hasOwnProperty("videoMetadata")) {
            additionsToObj["videoMetadata"] = newVideoObj.videoMetadata;
          }
          additionsToObj["thumbnailLoc"] = newVideoObj.thumbnailLoc;
          const oldVideoObj = state.videoList[videoId];

          return { ...state, videoList: {
            ...state.videoList, [videoId]: {...oldVideoObj, ...additionsToObj}
          }, refresh: !state.refresh }
        }
      return { ...state, 
        videoList: {...state.videoList, [videoId]: action.payload.videoObj},
        refresh: !state.refresh };
    case DELETE_VIDEOS_SUCCESS:
      const oldVideoList = state.videoList;

      return { ...state, refresh:!state.refresh,
        videoList: _.omit(state.videoList, action.payload.videoIds)
      }
        
      
    case ADD_METADATA_OPTION:
        return { ...state, refresh: !state.refresh}
    case DELETE_METADATA_OPTION:
        return { ...state}
    case EDIT_METADATA_OPTION:
        return { ...state, refresh: !state.refresh}
    case SET_VIDEO_METADATA_SUCCESS:
        const videoObj = state.videoList[action.payload.videoId];
        if (!videoObj) return state;
        action.payload.metadataObj.sort((a, b) => {
          return a.option_id < b.option_id
        });
        videoObj['metadata'] = action.payload.metadataObj;
        return { 
          ...state,
          refresh: !state.refresh,
          videoList: {
            ...state.videoList, [action.payload.videoId]: videoObj
          }
        }
    case GET_ALL_METADATA_FIELDS:
        return { ...state}
    case GET_ALL_METADATA_FIELDS_SUCCESS:
        return {
            ...state,
            optionsMetadataArray: action.payload.data.options,
            selectedMetadataObj: action.payload.data.selected,
            refresh: !state.refresh
        }
    case GET_THUMBNAIL_SUCCESS:
        if (!(action.payload.videoId in state.videoList)) {
          return state;
        }

        return {
          ...state,
          videoList: {
            ...state.videoList,
            [action.payload.videoId]: {
              ...state.videoList[action.payload.videoId],
              thumbnailLoc: action.payload.realThumbnailLoc
            }
          }
        }
    case LOADING_VIDEOS_START:
        return {...state, videosLoading: true, videosLoaded: false}

    case ALL_VIDEOS_LOADED:
        return {...state, videosLoading: false, videosLoaded: true}
    case MODIFY_VIDEO_DOC:
        // This means the object has already been initialized by INITIALIZE_INSPECTION_DATA
        // So it no longer needs to be updated locally
        if (state.videoList[action.payload.newData.key].hasOwnProperty("riskAssessment") &&
            state.videoList[action.payload.newData.key].riskAssessment['0'].hasOwnProperty("initialized")){
          return state;
        }

        const key = action.payload.newData.key;
        const newObj = { ...action.payload.newData, 
                         thumbnailLoc: state.videoList[key].thumbnailLoc, 
                         visible: state.videoList[key].visible }

        return Object.assign({}, state, {
            videoList: {...state.videoList, [key]: newObj}
          });

    case INITIALIZE_INSPECTION_DATA:
      // This reducer is meant to "hydrate" the data pulled from Firebase
      const newVideoList = state.videoList;
      for (let i = 0; i < newVideoList[action.payload.videoId].numPeople; i++) {
        const personId = String(i);
        if (newVideoList[action.payload.videoId].riskAssessment[personId].hasOwnProperty("initialized")){
          continue;
        }
        const assessmentType = newVideoList[action.payload.videoId].assessmentType;
        const personInspectionData = newVideoList[action.payload.videoId].riskAssessment[personId];
        if (assessmentType === Assessments["RULA"]) {
          newVideoList[action.payload.videoId].riskAssessment[personId] = new Rula(personInspectionData);
        } else if (assessmentType === Assessments["REBA"]) {
          newVideoList[action.payload.videoId].riskAssessment[personId] = new Reba(personInspectionData);
        } else if (assessmentType === Assessments["NIOSH"]) {
          console.log("INTIALIZING")
          newVideoList[action.payload.videoId].riskAssessment[personId] = new Niosh(personInspectionData);
        }
      }
      return Object.assign({}, state, {
        ...state,
        videoList: newVideoList
      });

    case GET_JOINT_DATA_SUCCESS:
      state.videoList[action.payload.videoId].videoJsons = action.payload.data;
      state.videoList[action.payload.videoId].chunk = action.payload.chunk;
      return Object.assign({}, state, {
        ...state,
        refresh: !state.refresh,
      });

    case UPDATE_ADDITIONAL_INFO:
        state.videoList[action.payload.videoId].riskAssessment[
          action.payload.personId
        ].updateAdditionalInfo(
          action.payload.newInfo['type'],
          action.payload.newInfo['bodyGroup'],
          action.payload.newInfo['newValue'],
        );
      return {...state, refresh: !state.refresh} ;

    case GENERATE_REPORT_REQUEST:
      return {...state, reportLoading: true}
    case GENERATE_REPORT_SUCCESS:
      return {...state, reportLoading: false}
    case GENERATE_REPORT_ERROR:
      return {...state, reportLoading: false}

    case UPDATE_RISK_COMPONENTS:
        state.videoList[action.payload.videoId].riskAssessment[
          action.payload.personId
        ].updateRiskComponents(
          action.payload.newInfo['bodyGroup'],
          action.payload.newInfo['type'],
          action.payload.newInfo['newValue'],
        );
      return {...state, refresh: !state.refresh} ;

      case SUBMIT_VIDEO_FEEDBACK:
          return {...state}


    default: return { ...state };
  }
}
