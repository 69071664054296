import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';

import { auth } from '../../helpers/Firebase';
import { cognitoAuthUser, cognitoRegisterUser, cognitoChangePassword } from 'tumeke-database/Cognito';
import AsyncStorage from '@react-native-community/async-storage';
import {
  loginUser,
  createUser,
  createCompany,
  getUserById,
  getUserByCognitoId,
  determineUserRole,
  convertUserToFirebaseUserObj,
  migrateFirebaseIdtoCognito,
  joinCompanyRequest,
  getCompanyById,
  checkLoginStatus
} from '../../helpers/DatabaseHelpers';


import { initializeVirgil, getVirgilPrivateKey, decryptAESKeys,
         logoutVirgil, createNewUserVirgil, joinGroup, isVirgilInitialized,
         changePassword } from '../../helpers/VirgilHelpers';
import { TUMEKE_API } from '../../constants/config';
import { NotificationManager } from "../../components/common/react-notifications";
import { Mixpanel } from '../../helpers/Mixpanel.js';

import {
    LOGIN_USER,
    REGISTER_USER,
    LOGOUT_USER,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    SET_DECYPTED_AES_KEYS_REQUEST,
    SET_DECYPTED_AES_KEYS_SUCCESS,
    DETERMINE_USER_ROLE_REQUEST,
    ASK_FOR_PASSWORD_REQUEST,
    ENTERED_PASSWORD_REQUEST,
    CHECK_PASSWORD,
    SET_COMPANY_METADATA_SUCCESS
} from '../actions';

import {
    loginUserSuccess,
    loginUserError,
    registerUserSuccess,
    registerUserError,
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError,
    setDecryptedAESKeysSuccess,
    determineUserRoleSuccess,
    askForPasswordShowModal,
    cancelAskPassword,
    enterPasswordError,
    logoutUser,
    virgilInitError,
    setDecyptingKeys,
    setDecryptedAESKeysRequest
} from './actions';

import {
    removeVideoListeners
} from '../videos/actions';

import {
    removeNotificationListener
} from '../notifications/actions';

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

export function* watchRequestAESDecryptSuccess() {
    yield takeEvery(SET_DECYPTED_AES_KEYS_SUCCESS, aesKeySuccessSaga);
}

export function* watchEnteredPasswordRequest() {
    yield takeEvery(ENTERED_PASSWORD_REQUEST, enteredPasswordRequestSaga);
}

export function* watchRequestAESDecrypt() {
    yield takeEvery(SET_DECYPTED_AES_KEYS_REQUEST, decryptAESKeysSaga);
}

export function* watchSetCompanyMetadataSuccess() {
    yield takeEvery(SET_COMPANY_METADATA_SUCCESS, setCompanyMetadataSuccessSaga);
}

export function* watchCheckPassword() {
    yield takeEvery(CHECK_PASSWORD, checkPasswordSaga);
}

const cognitologinWithEmailPasswordAsync = async (email, password) =>
    await cognitoAuthUser(email,password)
        .then(authUser => authUser)
        .catch(error => error);

const asyncStore = async (key,val) => await AsyncStorage.setItem(key, val)

const loginWithEmailPasswordAsync = async (email, password) =>
    await auth.signInWithEmailAndPassword(email, password)
        .then(authUser => authUser)
        .catch(error => error);

function* aesKeySuccessSaga({ payload }) {
    console.log("in setting keys success")
    if (payload.callback === undefined) return;
    console.log("Calling callback");
    payload.callback();
}

function* decryptAESKeysSaga({ payload }) {
    // TODO: Clean up this logic
    const state = yield select();
    console.log("Checking already decrypting");
    if (state.authUser.decryptingKeys) {
        console.log("Already decrypting, quitting");
        return;
    }
    yield put(setDecyptingKeys(true));
    console.log("Checking user valid")
    if (!state.authUser.user) {
        console.log("User not valid, quitting")
        yield put(setDecyptingKeys(false));
        return;
    }
    console.log("Checking if already decrypted keys")
    if (state.authUser.user.hasOwnProperty("decryptedAESKeys")) {
        console.log("Already decrypted keys")
        yield put(setDecyptingKeys(false));
        return;
    }
    console.log("Initialized? " + isVirgilInitialized())
    if (isVirgilInitialized()) {
        console.log("User: " + JSON.stringify(state.authUser.user));
        const decryptedKeys = yield call(decryptAESKeys, state.authUser.user, state.authUser.company);
        console.log("calling dispatch with callback");
        yield put(setDecryptedAESKeysSuccess(decryptedKeys, payload.callback));
        yield put(setDecyptingKeys(false));
        return;
    }
    const uid = state.authUser.user.uid;
    const password = payload.password ? payload.password : "";
    try {
        yield call(initializeVirgil);
        yield call(getVirgilPrivateKey, password, false);
        yield call(joinGroup, state.authUser.user, state.authUser.company);
    } catch (e) {
        console.log("DECRYPT KEYS VIRGIL ERROR: " + e);
        yield put(virgilInitError());
        yield put(setDecyptingKeys(false));
        return;
    }
    try {
        const decryptedKeys = yield call(decryptAESKeys, state.authUser.user, state.authUser.company)
        yield put(setDecryptedAESKeysSuccess(decryptedKeys, payload.callback));
        yield put(cancelAskPassword());
    } catch (error) {
        console.log(error);
        yield put(enterPasswordError("Password incorrect"));
    }

    yield put(setDecyptingKeys(false));
    // Commented out this pathway for now because 
    // it turns out that restoring the private key doesn't
    // need the password to be entered again.
    //yield put(askForPasswordShowModal());
}

function* enteredPasswordRequestSaga({ payload }) {
    console.log(payload);
    const { password } = payload;
    const state = yield select();
    const uid = state.authUser.user.uid;
    try {
        yield call(initializeVirgil);
        yield call(getVirgilPrivateKey, password, false);
        yield call(joinGroup, state.authUser.user, state.authUser.company);

        const decryptedKeys = yield call(decryptAESKeys, state.authUser.user, state.authUser.company)
        yield put(setDecryptedAESKeysSuccess(decryptedKeys));
        yield put(cancelAskPassword());

    } catch (error) {
        console.log(error);
        yield put(enterPasswordError("Password incorrect"));
    }
}

function* checkPasswordSaga({ payload }) {
    const state = yield select();
    console.log("CHECKING PASSWORD")
    const { history } = payload;
    try {
        const userObj = yield call(checkLoginStatus);
    } catch {
        yield put(logoutUser(history));
    }
}

function* setCompanyMetadataSuccessSaga({ payload }) {
    const state = yield select();
    const companyObj = state.authUser.company;
    yield call(asyncStore,'companyObj', JSON.stringify(companyObj));
}


function* loginWithEmailPassword({ payload }) {
    console.log("In login saga")
    const { email, password } = payload.user;
    const { history } = payload;
    let firebaseUser = null;
    let cognitoUser = null;
    let user = null;
    let userObj = null;
    let companyObj = null;
    let id_token = null;
    try {
        cognitoUser = yield call(cognitologinWithEmailPasswordAsync, email, password);
        console.log(cognitoUser)
        // START: User migration Shim
        if (cognitoUser.code && cognitoUser.code  == "UserNotFoundException") {
          console.log('no cognito user found, attempting to login to firebase')
          let firebaseUser = null;
          try {
            firebaseUser = yield call(loginWithEmailPasswordAsync, email, password);
          } catch (e) {
            yield put(loginUserError("Incorrect email/password"))
            return;
          }
          console.log('registering new user with cognito...')
          cognitoUser = yield call(registerWithEmailPasswordAsync, email, password, firebaseUser.user.uid);
          console.log('cognitouser',cognitoUser)
          if (cognitoUser.message) {
            yield put(loginUserError(cognitoUser.message));
            return;
          }
          cognitoUser = yield call(cognitologinWithEmailPasswordAsync, email, password);
          id_token = cognitoUser.getIdToken().getJwtToken()
          yield call(asyncStore,'id_token',id_token);

          const newlyRegistered = yield call(migrateFirebaseIdtoCognito, firebaseUser.user.email);
          console.log('cognitoUser before migration',cognitoUser)


          // error check here
          console.log('cognito migration success!')
        }
        // END: User migration Shim

        cognitoUser = yield call(cognitologinWithEmailPasswordAsync, email, password);
        if (cognitoUser.message) {
          yield put(loginUserError(cognitoUser.message));
          return;
        }
        console.log('cognitoUser',cognitoUser)

        id_token = cognitoUser.getIdToken().getJwtToken()
        yield call(asyncStore,'id_token',id_token);

        userObj = (yield call(loginUser));
        if (userObj.role === "none") {
            yield put(loginUserError("User not authorized to access this website."))
            return;
        }
        companyObj = (yield call(getCompanyById, userObj.company_id))

        console.log("Company obj", companyObj)
        console.log('user before firebase conversion',userObj)
        // userObj = yield call(convertUserToFirebaseUserObj,userObj)
        // if (userObj.message) {
        //      yield put(loginUserError(userObj.message));
        //      return;
        // }
        console.log('userObj',userObj)
        const userCookie = payload.user.isDemo ? "demoUserObj" : "userObj"
        const companyCookie = payload.user.isDemo ? "demoCompanyObj" : "companyObj"
        yield call(asyncStore,userCookie,JSON.stringify(userObj));
        yield call(asyncStore,companyCookie, JSON.stringify(companyObj));
        yield put(loginUserSuccess(userObj, companyObj));
        yield put(setDecryptedAESKeysRequest(() => {}, password))
        if (history)
            history.push('/app/home/home-nav');
        yield call(initializeMixpanel, email);
    } catch (e) {
        yield put(loginUserError("Login error"))
        return;
    }
    

}

export async function initializeMixpanel(email) {
    /* Mixpanel user creation and identification */
    try {
        await Mixpanel.alias(email);
        await Mixpanel.identify(email);
        await Mixpanel.people.set({
            "$email": email,
            "$last_login": new Date().toDateString('en-US', {
                "timeZone": 'America/Los_Angeles',
            }),
            "$user_type": [
                'znoland3@gmail.com',
                'diwakar.ganesan@gmail.com',
                'rnoland97@gmail.com',
            ].includes(email)
                ? 'internal'
                : 'external',
        });
        return;
    } catch (error) {
        console.log("MIXPANEL error: ", error);
        return;
    }
};

export async function resetMixpanel(email) {
    await Mixpanel.reset(email);
}


export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerUser);
}

async function requestNotifyEmail(companyId, requestingEmail) {
  const data = new FormData();
  data.append("companyId", companyId);
  data.append("requestingEmail", requestingEmail);
  let axiosConfig = {
    headers: {
      "Content-Type": "multipart/form-data",
      Auth: "4aa6a4e5-f837-4e82-90e4-6944e59d7054",
    },
    body: data,
    responseType: 'json'
  };
  const response = await axios.post(TUMEKE_API + '/notifyCompanyAdmin',
        data, axiosConfig);
  if ("error" in response.data) {
    return false;
  }
  return true;
}

const virgilIdGenerator = () => {
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  let str = ""
  for (let i = 0; i < 28; i++) {
    str += chars[Math.floor(Math.random() * 28)]
  }
  return str;
}

const registerWithEmailPasswordAsync = async (email, password, virgilId) =>
      cognitoRegisterUser(email, password, virgilId)
        .then(authUser => authUser)
        .catch(error => error);

function* registerUser({ payload }) {
    const { name, email, password, companyName, isCreatingNewOrg} = payload.user;
    const { history } = payload;
    let company = null
    let user = null
    try {
        // COGNITO USER REGISTER
        const virgilId = virgilIdGenerator();
        console.log("Generated virgil id: " + virgilId)
        const registerUser = yield call(
            registerWithEmailPasswordAsync,
            email,
            password,
            virgilId
        );
        console.log('registered user', registerUser)
        if (!registerUser.message) {
            let cognito_user =  yield call(
                cognitologinWithEmailPasswordAsync,
                email,
                password
            );
            const id_token = cognito_user.getIdToken().getJwtToken()
            yield call(asyncStore,'id_token', id_token);
            yield call(initializeVirgil)
            yield call(createNewUserVirgil, password)

            // CREATE USER DOC
            yield call(createUser, payload.user)
            if (isCreatingNewOrg){
                company = yield call(createCompany, virgilId, companyName)
                yield call (asyncStore, 'companyObj', JSON.stringify(company));
                console.log("COMPANY ID: " + company.id);
            } else {
                console.log("Requesting company join")
                yield call(joinCompanyRequest, companyName)
            }
            console.log("logging in....")
            user = yield call(loginUser)
            console.log("Got user obj")
            yield call (asyncStore, 'userObj', JSON.stringify(user));
            console.log("Stored user obj")
            if (isCreatingNewOrg) {
                yield put(registerUserSuccess(user, company));
            } else {
                yield put(registerUserSuccess(user));
            }
            
            history.push('/')
            yield call(initializeMixpanel, email);
        } else {
            yield put(registerUserError(registerUser.message));

        }
    } catch (error) {
        //yield put(registerUserError(error));
    }
}



export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    await auth.signOut()
}

function* logout({ payload }) {
    const { history } = payload
    console.log("logout")
    try {
        if (!isVirgilInitialized()) {
             yield call(initializeVirgil);
        }
        yield call (logoutVirgil);
        try {
            yield call (logoutAsync, history);
        } catch (err) {
            console.log("error on logout: " + err)
        }
        yield put(removeVideoListeners());
        yield put(removeNotificationListener());
        localStorage.removeItem('userObj');
        yield call(resetMixpanel);
    } catch (error) {
        localStorage.removeItem('userObj');
    }
    // Sometimes history not passed in
    if (history) {
        history.push('/')
    }
    
}

export function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
    return await auth.sendPasswordResetEmail(email)
        .then(user => user)
        .catch(error => error);
}

function* forgotPassword({ payload }) {
    const { email } = payload.forgotUserMail;
    try {
        const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
        if (!forgotPasswordStatus) {
            yield put(forgotPasswordSuccess("success"));
        } else {
            yield put(forgotPasswordError(forgotPasswordStatus.message));
        }
    } catch (error) {
        yield put(forgotPasswordError(error));

    }
}

export function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
}

const resetPasswordAsync = async (oldPassword, newPassword) => {
    await auth.currentUser.updatePassword(newPassword);
}

const cognitoChangePasswordAsync = async (user, oldPass, newPassword) =>
    await cognitoChangePassword(user, oldPass, newPassword)
        .then(authUser => authUser)

function* resetPasswordSaga({ payload }) {
    const { oldPassword, newPassword_1, newPassword_2 } = payload;

    console.log("oldPassword: " +oldPassword);
    console.log("newPassword: " +newPassword_1);
    console.log("In change password thunk");
    const state = yield select();
    
    
    if (newPassword_1 !== newPassword_2) {
        NotificationManager.warning(
            "New passwords don't match",
            "Reset Password Error",
            3000,
            null,
            null,
            ''
          );
        yield put(resetPasswordError("New passwords don't match"));
        return;
    }

    if (newPassword_1.length < 6) {
        NotificationManager.warning(
            "New password must be at least 6 characters",
            "Reset Password Error",
            3000,
            null,
            null,
            ''
          );
        yield put(resetPasswordError("New password must be at least 6 characters"));
        return;
    }
    if (!isVirgilInitialized()) {
        try {
            yield call(initializeVirgil);
            yield call(getVirgilPrivateKey, oldPassword, false);
            yield call(joinGroup, state.authUser.user, state.authUser.company);
        } catch (e) {
            yield put(virgilInitError());
        }
    }

    try {
      console.log("Changing password");
      yield call(changePassword, oldPassword, newPassword_1);
    } catch (err) {
      console.log(err);
      // If this error happens then there was a problem with old password
       NotificationManager.warning(
            "Old password wrong",
            "Reset Password Error",
            3000,
            null,
            null,
            ''
          );
      yield put(resetPasswordError("Old password wrong"));
      return;
    }
    
    try {
        console.log("Changing password.... cognito")
        yield call(cognitoChangePasswordAsync, state.authUser.user.email, oldPassword, newPassword_1);
    } catch (err) {
        console.log(err);
         NotificationManager.warning(
            "Password set error",
            "Reset Password Error",
            3000,
            null,
            null,
            ''
          );
        yield put(resetPasswordError("Password set error"));
        yield call(changePassword, newPassword_1, oldPassword);
        return;
    }
    NotificationManager.success(
                `Successfully updated`,
                "Password",
                3000,
                null,
                null,
                "filled"
            );
    yield put(resetPasswordSuccess());
}

export function* watchDetermineUserRoleRequest() {
    yield takeEvery(DETERMINE_USER_ROLE_REQUEST, determineUserRoleRequestHelper);
}

function* determineUserRoleRequestHelper({ payload }) {
    // TODO role is now just an object in user, this flow should be deprecated
    const role = yield call(determineUserRole, payload.uid);
    yield put(determineUserRoleSuccess(role));
}

export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchLogoutUser),
        fork(watchRegisterUser),
        fork(watchForgotPassword),
        fork(watchResetPassword),
        fork(watchRequestAESDecrypt),
        fork(watchDetermineUserRoleRequest),
        fork(watchEnteredPasswordRequest),
        fork(watchRequestAESDecryptSuccess),
        fork(watchCheckPassword),
        fork(watchSetCompanyMetadataSuccess)
    ]);
}
