import { RULAComponentRiskScore } from './RulaRebaConfig';
import cloneDeep from 'lodash/cloneDeep';

export class Rula {
  computeRula = () => {
    const wrist = this.getRiskComponentValue("Wrist", "Score")
    const upper_arm = this.getRiskComponentValue("Upper Arm", "Score") + 
      this.getRiskComponentValue("Upper Arm", "Abducted") + 
      this.getRiskComponentValue("Upper Arm", "ShoulderRaised")
    const lower_arm = this.getRiskComponentValue("Lower Arm", "Score") +
      this.getRiskComponentValue("Lower Arm", "Midline")
    const table_a_score = Rula.rulaTableA[wrist][upper_arm - 1][lower_arm - 1]

    const trunk_score = this.getRiskComponentValue("Trunk", "Score") +
      this.getRiskComponentValue("Trunk", "Twist") +
      this.getRiskComponentValue("Trunk", "SideBend")
    const leg_score = 1
    const neck_score = this.getRiskComponentValue("Neck", "Score") + 
      this.getRiskComponentValue("Neck", "Twist") +
      this.getRiskComponentValue("Neck", "SideBend")
    const table_b_score = Rula.rulaTableB[trunk_score][leg_score][neck_score - 1]


    const finalArm = Math.min(
      this.additionalInputs['Muscle Use']['Arm'] +
        this.additionalInputs['Force']['Arm'] +
        table_a_score,
      8,
    );
    const finalLeg = Math.min(
      this.additionalInputs['Muscle Use']['Leg'] +
        this.additionalInputs['Force']['Leg'] +
        table_b_score,
      7,
    );

    this.assessmentResult = {
      ...this.assessmentResult, ...this.generateRiskInfo(Rula.rulaTable[finalLeg][finalArm - 1])
    };

    this.assessmentResult['Components'] = {};
    this.assessmentResult['Components']['Trunk'] = this.generateRiskInfoComponent(
      "Trunk",
      trunk_score,
    );

    this.assessmentResult['Components']['Upper Arm'] = 
      this.generateRiskInfoComponent("Upper Arm", upper_arm);
    this.assessmentResult['Components']['Lower Arm'] = 
      this.generateRiskInfoComponent("Lower Arm", lower_arm);
    this.assessmentResult['Components']['Neck'] = 
      this.generateRiskInfoComponent("Neck", neck_score);
  };

  getRulaColor = (score) => {
    if (score <= 2) {
      return '#6DD0A3';
    }
    if (score <= 4) {
      return '#14D2B8';
    }
    if (score == 5) {
      return '#ea8282';
    }
    if (score >= 6) {
      return '#b80f0a';
    }
  };

  getRulaText = (score) => {
    if (score <= 2) {
      return 'Acceptable';
    }
    if (score <= 4) {
      return 'Low risk of injury';
    }
    if (score == 5) {
      return 'Medium risk of injury';
    }
    if (score >= 6) {
      return 'High risk of injury';
    }
  };

  getRulaShortText = (score) => {
    if (score <= 2) {
      return 'Acceptable';
    }
    if (score <= 4) {
      return 'Low';
    }
    if (score == 5) {
      return 'Medium';
    }
    if (score >= 6) {
      return 'High';
    }
  };

  generateRiskInfo = (score) => {
    const mappedScore = score;
    return {
      Score: mappedScore,
      Color: this.getRulaColor(mappedScore),
      Text: this.getRulaText(mappedScore),
      ShortText: this.getRulaShortText(mappedScore)
    };
  };

  generateRiskInfoComponent = (bodyPart, score) => {
    return {
      "Score": score,
      "Color": RULAComponentRiskScore[bodyPart][String(score)]["Color"],
      "ShortText": RULAComponentRiskScore[bodyPart][String(score)]["ShortText"]
    }
  }

  updateAdditionalInfo = (bodyGroup, info, newValue) => {
    this.additionalInputs[bodyGroup][info] = newValue;
    this.computeRula();
  };

  updateRiskComponents = (bodyGroup, type, newValue) => {
    this.riskComponents[bodyGroup][type] = newValue;
    this.computeRula();
  };


  constructor(oldObject) {
    this.initialized = true;
    if (oldObject == null) {
      this.assessmentResult = {};
      this.riskComponents = {};
      this.additionalInputs = {};
      this.highestRiskTime = 0;
      return;
    }
    this.assessmentResult = cloneDeep(oldObject.assessmentResult);
    this.additionalInputs = cloneDeep(oldObject.additionalInputs);
    this.riskComponents = cloneDeep(oldObject.riskComponents);
    this.highestRiskFrame = cloneDeep(oldObject.highestRiskFrame);
    this.warningsExist = cloneDeep(this.doWarningsExist(oldObject.riskComponents));
    this.computeRula();
  }

  doWarningsExist = (riskComponentsOldObject) => {
    let warnings = false
    for(const key of Object.keys(riskComponentsOldObject)) {
      if (typeof riskComponentsOldObject[key] === "object") {
        warnings |= this.doWarningsExist(riskComponentsOldObject[key])
      } else if (riskComponentsOldObject[key] === -1) {
        warnings = true
      }
    }
    return warnings
  }


  getRiskComponentValue = (bodyPart, componentId) => {
    if (!this.riskComponents.hasOwnProperty(bodyPart)) {
      return 0;
    }
    if (!this.riskComponents[bodyPart].hasOwnProperty(componentId)) {
      return 0;
    }
    if (this.riskComponents[bodyPart][componentId] === -1) {
      return Rula.Schema[bodyPart][componentId]
    }
    return this.riskComponents[bodyPart][componentId]
  }
}

Rula.Schema = {}
Rula.Schema['Trunk'] = {}
Rula.Schema['Trunk']['Score'] = 1
Rula.Schema['Trunk']['SideBend'] = 0
Rula.Schema['Trunk']['Twist'] = 0
Rula.Schema['Trunk']["Warnings"] = {}

Rula.Schema['Neck'] = {}
Rula.Schema['Neck']['Score'] = 1
Rula.Schema['Neck']['SideBend'] = 0
Rula.Schema['Neck']['Twist'] = 0
Rula.Schema['Neck']["Warnings"] = {}

Rula.Schema['Upper Arm'] = {}
Rula.Schema['Upper Arm']['Score'] = 1
Rula.Schema['Upper Arm']['Abducted'] = 0
Rula.Schema['Upper Arm']['ShoulderRaised'] = 0
Rula.Schema['Upper Arm']["Warnings"] = {}

Rula.Schema['Lower Arm'] = {}
Rula.Schema['Lower Arm']['Score'] = 1
Rula.Schema['Lower Arm']['Midline'] = 0
Rula.Schema['Lower Arm']["Warnings"] = {}

Rula.Schema['Wrist'] = {}
Rula.Schema['Wrist']["Score"] = 1
Rula.Schema['Wrist']["Warnings"] = {}

//wrist, upper_arm - 1, lower_arm - 1
Rula.rulaTableA = {}
Rula.rulaTableA[1] = [[1, 2, 2], [2, 3, 3], [3, 3, 4], [4, 4, 4], [5, 5, 6], [7, 8, 9]]
Rula.rulaTableA[2] = [[2, 2, 3], [3, 3, 4], [4, 4, 4], [4, 4, 4], [5, 6, 6], [7, 8, 9]]
Rula.rulaTableA[3] = [[2, 3, 3], [3, 3, 4], [4, 4, 4], [4, 4, 5], [5, 6, 7], [7, 8, 9]]
Rula.rulaTableA[4] = [[3, 3, 4], [4, 4, 5], [5, 5, 5], [5, 5, 6], [6, 7, 7], [8, 9, 9]]

//[Trunk, leg, neck - 1]
Rula.rulaTableB = {}
Rula.rulaTableB[1] = {}
Rula.rulaTableB[2] = {}
Rula.rulaTableB[3] = {}
Rula.rulaTableB[4] = {}
Rula.rulaTableB[5] = {}
Rula.rulaTableB[6] = {}

Rula.rulaTableB[1][1] = [1, 2, 3, 5, 7, 8]
Rula.rulaTableB[1][2] = [3, 3, 3, 5, 7, 8]
Rula.rulaTableB[2][1] = [2, 2, 3, 5, 7, 8]
Rula.rulaTableB[2][2] = [3, 3, 4, 6, 7, 8]
Rula.rulaTableB[3][1] = [3, 4, 4, 6, 7, 8]
Rula.rulaTableB[3][2] = [4, 5, 5, 7, 8, 8]
Rula.rulaTableB[4][1] = [5, 5, 5, 7, 8, 8]
Rula.rulaTableB[4][2] = [5, 5, 6, 7, 8, 9]
Rula.rulaTableB[5][1] = [6, 6, 6, 7, 8, 9]
Rula.rulaTableB[5][2] = [6, 7, 7, 7, 8, 9]
Rula.rulaTableB[6][1] = [7, 7, 7, 8, 8, 9]
Rula.rulaTableB[6][2] = [7, 7, 7, 8, 8, 9]

Rula.rulaTable = {};
Rula.rulaTable[1] = [1, 2, 3, 3, 4, 4, 5, 5];
Rula.rulaTable[2] = [2, 2, 3, 3, 4, 4, 5, 5];
Rula.rulaTable[3] = [3, 3, 3, 3, 4, 5, 6, 6];
Rula.rulaTable[4] = [3, 4, 4, 4, 5, 6, 6, 7];
Rula.rulaTable[5] = [4, 4, 4, 5, 6, 6, 7, 7];
Rula.rulaTable[6] = [5, 5, 5, 6, 7, 7, 7, 7];
Rula.rulaTable[7] = [5, 5, 6, 6, 7, 7, 7, 7];